import { FC } from 'react';
import { Box } from '@mui/material';
import RightDrawer from 'components/Drawers/RightDrawer';
import LeftDrawer from 'components/Drawers/LeftDrawer';
import CustomAppBar from 'components/AppBar';
import { Outlet } from 'react-router-dom';

type Props = {
	leftDrawerChildren?: React.ReactNode;
};

const DrawersLayout: FC<Props> = ({ leftDrawerChildren }) => {
	return (
		<Box sx={{ width: '100%', height: '100%' }}>
			<CustomAppBar />
			<LeftDrawer>{leftDrawerChildren}</LeftDrawer>
			<Outlet />
			<RightDrawer />
		</Box>
	);
};

export default DrawersLayout;
