import { useQuery } from 'react-query';
import * as api from 'net/api';
import { useHeaders } from 'hooks/useHeaders';

export const usePlants = () => {
	const headers = useHeaders();

	return useQuery(['plants'], () => api.plant.fetchAll(headers));
};

export const usePlantById = (id: string) => {
	const headers = useHeaders();
	const { data: simplePlantList } = usePlants();
	const plantIdConsistOfNumber = id.match(/^[0-9]+$/) != null;
	let plantId = +id;

	if (!plantIdConsistOfNumber) {
		plantId = simplePlantList?.find((plant) => plant.name === id.replace('-', ' '))?.id as number;
	}

	return useQuery(['plant', plantId], () => api.plant.fetchById(plantId, headers), {
		enabled: plantId >= 1,
	});
};
