import { useRoutes, Navigate, RouteObject } from 'react-router-dom';
import MessagePage from 'pages/MessagePage';
import MainLayout from 'layouts/MainLayout';
import Plants from 'pages/Plants';
import DrawersLayout from 'layouts/DrawersLayout';
import LibraryMenu from 'components/Menus/LibraryMenu';
import PlantSettingsMenu from 'components/Menus/PlantSettingsMenu';
import ToolbarLayout from 'layouts/ToolbarLayout';
import PlantSettings from 'pages/PlantSettings';
import PlantEmpty from 'pages/EmptyPage';
import PlantBoundariesConditions from 'pages/PlantBoundariesConditions';
import Variants from 'pages/Variants';

export default function PageRoutes() {
	const mainRoutes: RouteObject = {
		path: '/',
		element: <MainLayout />,
		children: [
			{ index: true, element: <Navigate to="plants" /> },
			{ path: '*', element: <Navigate to="/404" /> },
		],
	};

	const pageNotFoundRoutes: RouteObject = {
		path: '/404',
		element: <ToolbarLayout />,
		children: [
			{
				index: true,
				element: <MessagePage title="Page not found!" description="The page you are looking for, doesn't exist!" />,
			},
		],
	};

	const plantsRoutes: RouteObject = {
		path: 'plants',
		element: <ToolbarLayout />,
		children: [{ index: true, element: <Plants /> }],
	};

	const plantRoutes: RouteObject = {
		path: 'plants/:plantId',
		element: <DrawersLayout leftDrawerChildren={<PlantSettingsMenu />} />,
		children: [
			{ index: true, element: <Navigate to="settings" /> },
			{
				path: 'settings',
				element: <PlantSettings />,
			},
			{
				path: 'files',
				element: <PlantEmpty title="Files" />,
			},
			{
				path: 'existing-assets',
				element: <PlantEmpty title="Existing assets" />,
			},
			{
				path: 'boundaries-conditions',
				element: <PlantBoundariesConditions />,
			},
			{ path: '*', element: <Navigate to="/404" /> },
		],
	};

	const variants: RouteObject = {
		path: 'plants/:plantId/variants',
		element: <DrawersLayout leftDrawerChildren={<LibraryMenu />} />,
		children: [
			{
				index: true,
				element: <Variants />,
			},
			{ path: '*', element: <Navigate to="/404" /> },
		],
	};

	const scenarios: RouteObject = {
		path: 'plants/:plantId/scenarios',
		element: <DrawersLayout leftDrawerChildren={<LibraryMenu />} />,
		children: [
			{
				index: true,
				element: <PlantEmpty title="Scenarios" />,
			},
			{ path: '*', element: <Navigate to="/404" /> },
		],
	};

	return useRoutes([mainRoutes, plantsRoutes, plantRoutes, variants, scenarios, pageNotFoundRoutes]);
}
