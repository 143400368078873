import { EField } from 'models/enums';
import { FieldMetadataDto, FieldType } from 'models/types';

export type BCFieldType = Omit<FieldType, 'typeObject' | 'scriptName'>;
export type IDType = number;

export type BCSourceType = {
	id: IDType;
	name: string;
	description: string;
	fields: BCFieldType[];
};

export type BCScenarioCellType = {
	id: IDType;
	name: string;
	value: number;
};

export const INFLUENT_DATA: BCSourceType = {
	id: 1,
	name: 'Influent',
	description: '',
	fields: [
		{
			id: 1,
			name: 'Cod',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '19399',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 2,
			name: 'Bod5',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '8205',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 3,
			name: 'q_day',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '33149',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 4,
			name: 'q_avg_dwf',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '1062',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 5,
			name: 'q_max',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '8000',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 6,
			name: 'tkn',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '1531',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 7,
			name: 'tp',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '265',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 8,
			name: 'tss',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '8533',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 9,
			name: 'nox',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '11',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 10,
			name: 'don',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '1.7',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 11,
			name: 'pf_dwf_max',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '1.7',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 12,
			name: 'pf_dwf_min',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '0.5',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
	],
};

export const WATER_DATA: BCSourceType = {
	id: 2,
	name: 'Water',
	description: '',
	fields: [
		{
			id: 13,
			name: 'BOD',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '19399',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 14,
			name: 'COD',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '8205',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
		{
			id: 15,
			name: 'TSS',
			type: EField.INT,
			unit: '',
			description: '',
			defaultValue: '33149',
			nullable: false,
			metaData: {} as FieldMetadataDto,
		},
	],
};

export const BC_SOURCE_TYPE_LIST_DATA: BCSourceType[] = [INFLUENT_DATA, WATER_DATA];
