import { FC, Fragment, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Typography, LinearProgress } from '@mui/material';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { wsResponseAtom } from 'store/atoms/websocket/wsResponse';
import { calculationIsRunningAtom } from 'store/atoms/calculationIsRunning';
import TableDialog from 'components/TableDialog';
import CustomGridToolbar from './CustomGridToolbar';

const columns: GridColDef[] = [
	{ field: 'id', headerName: 'ID', flex: 1, hide: true },
	{ field: '0', headerName: 'Variable', flex: 1 },
	{ field: '1', headerName: 'Value', flex: 1 },
];

const ResultTable: FC = () => {
	const [calculationIsRunning, setCalculationIsRunning] = useRecoilState(calculationIsRunningAtom);
	const { outputVariables: data } = useRecoilValue(wsResponseAtom);
	const calculationText = calculationIsRunning ? 'Calculating...' : 'Calculation Result';
	const [dataAsArray, setDataAsArray] = useState<GridRowsProp>([]);

	useEffect(() => {
		if (data) {
			setCalculationIsRunning(false);
			const arrayOfData: GridRowsProp = Object.entries(data).map((item, index) => ({
				...item,
				id: index,
			}));

			setDataAsArray(arrayOfData);
		}
	}, [data, setCalculationIsRunning]);

	useEffect(() => {
		if (calculationIsRunning) setDataAsArray([]);
	}, [calculationIsRunning]);

	if (!dataAsArray.length && !calculationIsRunning) return null;

	return (
		<Fragment>
			<Typography variant="h6">{calculationText}</Typography>
			{calculationIsRunning && <LinearProgress color="success" />}
			{data && (
				<DataGrid
					rows={dataAsArray}
					columns={columns}
					sx={{ height: 400 }}
					hideFooter
					disableSelectionOnClick
					components={{ Toolbar: CustomGridToolbar }}
				/>
			)}
			<TableDialog title={'Calculation Result'} dataAsArray={dataAsArray} columns={columns} />
		</Fragment>
	);
};

export default ResultTable;
