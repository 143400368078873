import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import ContentWrapper from 'components/ContentWrapper';

type Props = {
	title: string;
	description?: string;
};

const MessagePage: FC<Props> = ({ title, description }) => {
	return (
		<ContentWrapper margin={5} textAlign="center">
			<Typography variant="h1" style={{ color: 'white' }}>
				{title}
			</Typography>
			<Typography variant="h6" style={{ color: 'white' }}>
				{description}
			</Typography>
			<Button component={Link} to="/">
				Back Home
			</Button>
		</ContentWrapper>
	);
};

export default MessagePage;
