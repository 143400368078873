import { FC, useState, useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CustomTextField from 'components/shared/CustomTextField';
import { sourceHeaderToRenameAtom } from 'store/atoms/boundConditions/sourceHeaderToRename';
import { useRenameColumn } from 'net/mutations/boundariesConditions';

const BCHeaderRenameDialog: FC = () => {
	const currentColumn = useRecoilValue(sourceHeaderToRenameAtom);
	const resetHeaderRenameDialog = useResetRecoilState(sourceHeaderToRenameAtom);
	const [headerName, setHeaderName] = useState(currentColumn?.headerName);
	const { mutate: renameColumn } = useRenameColumn();

	useEffect(() => {
		const { headerName } = currentColumn || {};
		if (headerName) {
			setHeaderName(headerName);
		}
	}, [currentColumn, setHeaderName]);

	const closeDialog = () => {
		resetHeaderRenameDialog();
	};

	const handleValueOnChange = (value: string) => {
		setHeaderName(value);
	};

	const handleOnSave = () => {
		if (headerName) renameColumn(headerName);
		closeDialog();
	};

	return (
		<Dialog open={!!currentColumn} onClose={closeDialog} keepMounted={false} fullWidth maxWidth="sm">
			<DialogTitle>Rename source</DialogTitle>
			<DialogContent>
				<CustomTextField
					required
					margin="dense"
					label="Plant name"
					fullWidth
					variant="outlined"
					value={headerName || ''}
					onChange={(event) => handleValueOnChange(event.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="success" onClick={handleOnSave}>
					Save
				</Button>
				<Button color="warning" onClick={closeDialog}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default BCHeaderRenameDialog;
