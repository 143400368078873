import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import { FC } from 'react';

const PlantCardSkeleton: FC<SkeletonProps> = ({ ...props }) => {
	const { animation } = props;

	return (
		<Card
			sx={{
				maxWidth: 345,
				minWidth: 225,
				margin: 1,
			}}
		>
			<CardHeader
				avatar={<Skeleton animation={animation} variant="circular" width={40} height={40} />}
				action={null}
				title={<Skeleton animation={animation} height={10} width="80%" style={{ marginBottom: 6 }} />}
				subheader={<Skeleton animation={animation} height={10} width="40%" />}
			/>
			<CardContent>
				<Typography>
					<Skeleton animation={animation} height={10} style={{ marginBottom: 6 }} />
					<Skeleton animation={animation} height={10} width="80%" />
				</Typography>
			</CardContent>
		</Card>
	);
};

export default PlantCardSkeleton;
