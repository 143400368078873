import { useLocation, matchPath } from 'react-router-dom';
import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useComponentStylesBasesOnDrawerStatus } from 'hooks/useComponentStylesBasesOnDrawerStatus';

interface AppBarProps extends MuiAppBarProps {
	theme: Theme;
}

const propsToNotForward = ['empty'];

export const AppBarStyled = styled(MuiAppBar, {
	shouldForwardProp: (prop) => !propsToNotForward.includes(prop),
})<AppBarProps>(({ theme }) => {
	const location = useLocation();
	const pathMatch = matchPath({ path: '/plants/:plantId', end: false }, location.pathname);

	if (!pathMatch) return;

	const { width, marginLeft, marginRight, transition } = useComponentStylesBasesOnDrawerStatus(theme);

	return { width, marginLeft, marginRight, transition };
});
