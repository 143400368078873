import { FC } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { default as MuiSnackbar, SnackbarProps } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { notificationAtom } from 'store/atoms/notification';

const CustomSnackbar: FC<SnackbarProps> = ({ ...props }) => {
	const notification = useRecoilValue(notificationAtom);
	const showSnackbar = !!notification;
	const resetNotification = useResetRecoilState(notificationAtom);

	const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		event?.preventDefault();

		if (reason === 'clickaway') {
			return;
		}
		resetNotification();
	};

	const action = (
		<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
			<CloseIcon fontSize="small" />
		</IconButton>
	);

	return (
		<MuiSnackbar open={showSnackbar} onClose={handleClose} message={notification?.message} action={action} {...props}>
			<Alert onClose={handleClose} severity={notification?.status}>
				{notification?.message}
			</Alert>
		</MuiSnackbar>
	);
};

export default CustomSnackbar;
