import { FC, useEffect } from 'react';
import ContentWrapper from 'components/ContentWrapper';
import BCTable from 'components/BCGrid';
import { useRecoilState } from 'recoil';
import { BCSourceTypeAtom } from 'store/atoms/plants/BCSourceType';
import { useSourcesTypes } from 'net/queries/boundariesConditions';

const PlantBoundariesConditions: FC = () => {
	const { data: sources } = useSourcesTypes();
	const [selectedBCFlow, setSelectedBCFlow] = useRecoilState(BCSourceTypeAtom);

	useEffect(() => {
		if (!selectedBCFlow && sources?.length) {
			setSelectedBCFlow(sources[0].id);
		}
	}, [selectedBCFlow, setSelectedBCFlow, sources]);

	return (
		<ContentWrapper padding={5} height="calc(100% - 64px)">
			<BCTable />
		</ContentWrapper>
	);
};

export default PlantBoundariesConditions;
