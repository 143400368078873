import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { wsResponseAtom } from 'store/atoms/websocket/wsResponse';

const useWebSocket = (url: string) => {
	const setWSResponse = useSetRecoilState(wsResponseAtom);
	const socketRef = useRef<WebSocket>();

	useEffect(() => {
		const webSocket = new WebSocket(url);

		webSocket.onopen = handleOpen;
		webSocket.onclose = handleClose;
		webSocket.onmessage = (event: MessageEvent) => {
			const data = JSON.parse(event.data);
			setWSResponse(data);
		};
		webSocket.onerror = handleError;

		socketRef.current = webSocket;

		return () => {
			socketRef.current?.close();
		};
	}, [setWSResponse, url]);

	function handleOpen() {
		console.log('Websocket opened');
	}

	function handleClose() {
		console.log('Websocket closed');
	}

	function handleError(event: Event) {
		console.error(event);
	}

	return socketRef.current;
};

export default useWebSocket;
