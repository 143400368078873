import { FC, Fragment } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CustomGridToolbar from 'components/Drawers/RightDrawer/ResultTable/CustomGridToolbar';
import TableDialog from 'components/TableDialog';

const columns: GridColDef[] = [
	{ field: 'id', headerName: 'ID', hide: true },
	{ field: 'name', headerName: 'Name', flex: 1 },
	{ field: 'description', headerName: 'Description', flex: 1 },
	{ field: 'value', headerName: 'Value', flex: 1 },
	{ field: 'unit', headerName: 'Unit', flex: 1 },
];

const ConstantTable: FC = () => {
	return (
		<Fragment>
			<DataGrid
				rows={[]}
				columns={columns}
				sx={{ height: '75vh' }}
				hideFooter
				disableSelectionOnClick
				components={{ Toolbar: CustomGridToolbar }}
			/>
			<TableDialog title="constants" dataAsArray={[]} columns={columns} />
		</Fragment>
	);
};

export default ConstantTable;
