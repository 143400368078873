import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { default as MUIBreadcrumbs } from '@mui/material/Breadcrumbs';
import useBreadcrumbs, { BreadcrumbMatch } from 'use-react-router-breadcrumbs';
import useGetPlantDataFromPath from 'hooks/useGetPlantDataFromMatch';
import { StyledBreadcrumb } from './BreadCrumbs.style';

const DynamicPlantBreadcrumb = ({ match }: { match: BreadcrumbMatch }) => {
	const { plant } = useGetPlantDataFromPath(match.pathname);
	return <span>{plant?.name}</span>;
};

const breadCrumbRoutes = [{ path: 'plant/:plantId', breadcrumb: DynamicPlantBreadcrumb }];

export default function CustomBreadcrumbs() {
	const navigate = useNavigate();
	const breadcrumbs = useBreadcrumbs(breadCrumbRoutes);
	const breadcrumbsFiltered = breadcrumbs.filter((b) => !b.match.params['*']);

	function handleClick(event: MouseEvent, path: string) {
		event.preventDefault();
		navigate(path);
	}

	return (
		<MUIBreadcrumbs aria-label="breadcrumb">
			{breadcrumbsFiltered.map(({ key, match, breadcrumb }, index) => {
				return (
					<StyledBreadcrumb
						disabled={breadcrumbsFiltered.length - 1 === index}
						key={key}
						component="a"
						href={match.pathname}
						label={breadcrumb}
						onClick={(event) => handleClick(event, match.pathname)}
					/>
				);
			})}
		</MUIBreadcrumbs>
	);
}
