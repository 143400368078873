import FolderIcon from '@mui/icons-material/Folder';
import ClassIcon from '@mui/icons-material/Class';
import HandymanIcon from '@mui/icons-material/Handyman';
import TopicIcon from '@mui/icons-material/Topic';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
import { BC_SOURCE_TYPE_LIST_DATA } from 'components/BCGrid/Data/BCSourceTypeData';
import { MenuObjectType, MUIIconType } from '../MenuTypes';

export const BASE_MENU_ITEM: Partial<MenuObjectType> = {
	id: 0,
	name: '',
	description: '',
	visible: true,
	disabled: false,
	isHeader: false,
	path: '',
	iconStart: null as unknown as MUIIconType,
	collapsible: false,
};

export const PLANT_SETTING_MENU_ITEMS: MenuObjectType[] = [
	{
		...BASE_MENU_ITEM,
		name: 'Settings',
		path: 'settings',
		iconStart: SettingsIcon,
	},
	{
		...BASE_MENU_ITEM,
		name: 'Files',
		path: 'files',
		iconStart: FolderIcon,
		disabled: true,
	},
	{
		...BASE_MENU_ITEM,
		name: 'Existing Assets',
		path: 'existing-assets',
		iconStart: ClassIcon,
		disabled: true,
	},
	{
		...BASE_MENU_ITEM,
		name: 'Bound. Conditions',
		path: 'boundaries-conditions',
		description: 'test',
		iconStart: SettingsInputCompositeIcon,
		collapsible: true,
		subMenu: [
			{
				...BASE_MENU_ITEM,
				name: 'Inputs',
				isHeader: true,
				subMenu: BC_SOURCE_TYPE_LIST_DATA.map((flow, index) => {
					return {
						...BASE_MENU_ITEM,
						name: flow.name,
						id: index + 1,
					};
				}),
			},
			{
				...BASE_MENU_ITEM,
				name: 'Effluent Eis',
				isHeader: true,
				subMenu: [],
			},
		],
	},
	{
		...BASE_MENU_ITEM,
		name: 'Variants',
		path: 'variants',
		iconStart: HandymanIcon,
		disabled: false,
	},
	{
		...BASE_MENU_ITEM,
		name: 'Scenarios',
		path: 'scenarios',
		iconStart: TopicIcon,
		disabled: false,
	},
];
