import { FC, Fragment, useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapGL, { Layer, Source, Viewport } from '@urbica/react-map-gl';
import CustomControls from './CustomControls';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const mapBoxLayers = [
	{
		name: 'Satellite',
		style: 'mapbox://styles/mapbox/satellite-v9',
	},
	{
		name: 'Map',
		style: 'mapbox://styles/mapbox/light-v10',
	},
	{
		name: 'Terrain',
		style: 'mapbox://styles/mapbox/outdoors-v11',
	},
];

// Default location is set to The Netherlands
const DEFAULT_VIEWPORT: Viewport = {
	latitude: 52.21158,
	longitude: 5.600489,
	zoom: 7,
};

type Props = {
	plantLocation?: string;
};

const MapBoxGL: FC<Props> = ({ plantLocation }) => {
	const [viewport, setViewport] = useState<Viewport>(DEFAULT_VIEWPORT);
	const [parsedLocation, setParsedLocation] = useState();

	useEffect(() => {
		if (plantLocation) {
			setParsedLocation(JSON.parse(plantLocation));
		}
	}, [plantLocation]);

	return (
		<MapGL
			style={{ width: '100%', height: '440px' }}
			mapStyle={mapBoxLayers[1].style}
			accessToken={MAPBOX_ACCESS_TOKEN}
			latitude={viewport.latitude}
			longitude={viewport.longitude}
			zoom={viewport.zoom}
			onViewportChange={setViewport}
			dragRotate={false}
			attributionControl={false}
			preserveDrawingBuffer={true}
		>
			<CustomControls />
			{parsedLocation && (
				<Fragment>
					<Source id="location" type="geojson" data={parsedLocation} />
					<Layer
						id="location"
						type="circle"
						source="location"
						paint={{
							'circle-radius': 10,
							'circle-color': '#77DD77',
						}}
					/>
				</Fragment>
			)}
		</MapGL>
	);
};

export default MapBoxGL;
