import { FC } from 'react';
import { green, red } from '@mui/material/colors';
import { Handle, HandleType, Position } from 'react-flow-renderer';

type Props = {
	type: HandleType
	position: Position,
}

const CustomHandle: FC<Props> = ({ type, position }) => {
	const bgColor = type === 'target' ? green[300] : red[300];

	return (
		<Handle type={type} position={position} style={{ backgroundColor: bgColor }} />
	);
};

export default CustomHandle;
