import { FC } from 'react';
import { useRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useTheme } from '@mui/material';
import { leftDrawerOpenAtom } from 'store/atoms/drawer/leftDrawerOpen';
import { DrawerStyled, DrawerHeaderStyled, DRAWER_WIDTH } from '../Drawers.style';

type Props = {
	children: React.ReactNode;
};

const LeftDrawer: FC<Props> = ({ children }) => {
	const [leftDrawerOpen, setLeftDrawerOpen] = useRecoilState(leftDrawerOpenAtom);
	const theme = useTheme();

	const handleOnClick = (event: React.MouseEvent) => {
		event.preventDefault();
		setLeftDrawerOpen((current) => !current);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<DrawerStyled theme={theme} variant="permanent" open={leftDrawerOpen} width={leftDrawerOpen ? DRAWER_WIDTH : 55}>
				<DrawerHeaderStyled theme={theme}>
					<IconButton onClick={handleOnClick}>{leftDrawerOpen ? <MenuOpenIcon /> : <MenuIcon />}</IconButton>
				</DrawerHeaderStyled>
				<Divider />
				{children}
			</DrawerStyled>
		</Box>
	);
};

export default LeftDrawer;
