import { FC } from 'react';
import { NodeProps, Position } from 'react-flow-renderer';
import { Card, CardContent, CardHeader, Divider, useTheme } from '@mui/material';
import { UnitOperationInstanceType } from 'models/types';
import { useUnitOperationById } from 'net/queries/unitOperation';
import CustomHandle from './CustomHandle';
import { unitOperationInstanceNodeStyle } from './Nodes.style';

const UnitOperationInstanceNode: FC<NodeProps<UnitOperationInstanceType>> = ({ id, selected, data }) => {
	const theme = useTheme();
	const borderColor = selected ? theme.palette.success.dark : theme.palette.grey;
	const { data: unitOperation } = useUnitOperationById(+data.unitOperationId);

	return (
		<Card
			key={id}
			variant="outlined"
			sx={{
				width: unitOperationInstanceNodeStyle.cardWidth,
				opacity: 0.9,
				borderColor: `${borderColor}`,
			}}
		>
			<CardHeader
				title={`${unitOperation?.classIdentifier} ${data?.id}`}
				titleTypographyProps={{ fontSize: unitOperationInstanceNodeStyle.titleFontSize, textAlign: 'center' }}
				sx={{ height: unitOperationInstanceNodeStyle.cardHeaderHeight, padding: '10px' }}
			/>
			<Divider />
			<CardContent sx={{ height: unitOperationInstanceNodeStyle.cardContentHeight }}>
				<CustomHandle type="target" position={Position.Left} />
				<CustomHandle type="source" position={Position.Right} />
			</CardContent>
		</Card>
	);
};

export default UnitOperationInstanceNode;
