import { FC } from 'react';
import Divider from '@mui/material/Divider';
import { Typography, useTheme } from '@mui/material';
import { UnitOperationInstanceType } from 'models/types';
import { useNodes, Node } from 'react-flow-renderer';
import { useUnitOperationById } from 'net/queries/unitOperation';
import { DrawerStyled, DrawerHeaderStyled, DRAWER_WIDTH } from '../Drawers.style';
import CustomTabs from './CustomTabs';

const RightDrawer: FC = () => {
	const theme = useTheme();
	const selectedNode = useNodes().find((node) => node.selected) as Node<UnitOperationInstanceType>;
	const { data: unitOperation } = useUnitOperationById(selectedNode?.data.unitOperationId);

	return (
		<DrawerStyled
			theme={theme}
			variant="permanent"
			open={!!selectedNode}
			anchor="right"
			width={selectedNode ? DRAWER_WIDTH : 0}
		>
			<DrawerHeaderStyled theme={theme} flexDirection="column">
				<Typography variant="h6">
					{unitOperation?.classIdentifier} {selectedNode?.id}
				</Typography>
			</DrawerHeaderStyled>
			<Divider />
			<CustomTabs />
		</DrawerStyled>
	);
};

export default RightDrawer;
