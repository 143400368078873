import { FC, Fragment, MouseEvent, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { Collapse, Divider, List, ListSubheader } from '@mui/material';
import { leftDrawerOpenAtom } from 'store/atoms/drawer/leftDrawerOpen';
import { useLocation, useNavigate } from 'react-router-dom';
import { BCSourceTypeAtom } from 'store/atoms/plants/BCSourceType';
import { MenuObjectType } from '../MenuTypes';
import MenuItem from '../MenuItems/MenuItem';
import MenuCollapsibleItem from '../MenuItems/MenuCollapsibleItem';
import { PLANT_SETTING_MENU_ITEMS } from './config';

const PlantSettingsMenu: FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [selectedBCSourceTypeId, setSelectedBCSourceTypeId] = useRecoilState(BCSourceTypeAtom);
	const [leftDrawerOpen, setLeftDrawerOpen] = useRecoilState(leftDrawerOpenAtom);

	const handleItemOnCLick = (event: MouseEvent, path?: string) => {
		event.preventDefault();
		!leftDrawerOpen && setLeftDrawerOpen(true);
		if (!path) return;
		navigate(path);
	};

	const handleCollapsibleOnClick = (path: string) => {
		!leftDrawerOpen && setLeftDrawerOpen(true);
		navigate(path);
	};

	const handleSourceTypeOnClick = useCallback(
		(menuSourceType: Partial<MenuObjectType>) => {
			if (menuSourceType.id) {
				setSelectedBCSourceTypeId(menuSourceType.id);
			}
		},
		[setSelectedBCSourceTypeId],
	);

	return (
		<List component="nav" aria-labelledby="nested-list">
			{PLANT_SETTING_MENU_ITEMS.map((item: MenuObjectType, index: number) => {
				const { collapsible, visible, name, path, subMenu } = item;
				const selected = location.pathname.includes(path as string);

				return collapsible ? (
					<MenuCollapsibleItem
						key={`collapsible_item_${item.name}`}
						callBack={() => handleCollapsibleOnClick(path as string)}
						selected={selected}
						isOpen={selected}
						data={item}
					>
						<Divider component="li" />
						<Collapse in={selected} timeout="auto" unmountOnExit>
							{subMenu && (
								<List component="div" disablePadding>
									{subMenu.map((sub) => {
										return sub.isHeader ? (
											<Fragment key={`header_${sub.name}`}>
												<ListSubheader sx={{ display: leftDrawerOpen ? 'auto' : 'none' }}>{sub.name}</ListSubheader>
												<Divider />
												{sub.subMenu?.map((source) => {
													return (
														<MenuItem
															key={`source_${source.name}`}
															selected={selectedBCSourceTypeId === source.id}
															data={source}
															sx={{ pl: 2, display: leftDrawerOpen ? 'auto' : 'none' }}
															onClick={() => handleSourceTypeOnClick(source)}
														/>
													);
												})}
											</Fragment>
										) : (
											<MenuItem key={`sub_${sub.name}`} selected={false} data={sub} sx={{ pl: 2 }} />
										);
									})}
								</List>
							)}
						</Collapse>
					</MenuCollapsibleItem>
				) : (
					visible && (
						<MenuItem
							key={`item_${name}_${index}`}
							selected={selected}
							data={item}
							onClick={(event) => handleItemOnCLick(event, item.path)}
						/>
					)
				);
			})}
		</List>
	);
};

export default PlantSettingsMenu;
