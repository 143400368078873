import { GlobalSettingsType, HeaderType } from 'models/types';
import { API_URL } from 'net/constants';

const GLOBAL_SETTINGS_ENDPOINT = `${API_URL}/globalSettings`;

export const fetchAll = async (headers: HeaderType): Promise<GlobalSettingsType> => {
	// const result = await fetch(GLOBAL_SETTINGS_ENDPOINT, { method: 'GET', headers })
	// 	.then((result: Response) => result.json())
	// 	.then((data: GlobalSettingsType) => data);
	// return result;

	return Promise.resolve({
		gitRepo: {
			url: 'git@github.com:RoyalHaskoningDHV/wwtp-python.git',
			publicSSH: 'xxxxx-xxx-xxx-xxxx-xxxxx-xxxx',
		},
	});
};

export const update = async (settings: GlobalSettingsType, headers: HeaderType): Promise<GlobalSettingsType> => {
	const result = await fetch(`${GLOBAL_SETTINGS_ENDPOINT}`, {
		method: 'PUT',
		headers,
		body: JSON.stringify(settings),
	})
		.then((result: Response) => result.json())
		.then((data: GlobalSettingsType) => data);

	return result;
};
