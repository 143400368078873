import React, { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import CustomGridToolbar from 'components/Drawers/RightDrawer/ResultTable/CustomGridToolbar';
import { useRecoilState } from 'recoil';
import { tableDialogOpenAtom } from 'store/atoms/tableDialog/tableDialog';

type Props = {
	title: string;
	columns: GridColDef[];
	dataAsArray: GridRowsProp;
};

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const TableDialog: FC<Props> = ({ title, columns, dataAsArray }) => {
	const [open, setOpen] = useRecoilState(tableDialogOpenAtom);

	const handleOpen = (value: boolean) => {
		setOpen(value);
	};

	return (
		<Dialog fullScreen open={open} onClose={() => handleOpen(false)} TransitionComponent={Transition}>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={() => handleOpen(false)} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
						{title}
					</Typography>
				</Toolbar>
			</AppBar>
			<DataGrid
				rows={dataAsArray}
				columns={columns}
				sx={{ height: '100%' }}
				hideFooter
				disableSelectionOnClick
				components={{ Toolbar: CustomGridToolbar }}
			/>
		</Dialog>
	);
};

export default TableDialog;
