import styled from '@emotion/styled';
import { Chip, emphasize, Theme } from '@mui/material';
import { ChipProps as MUIChipProps } from '@mui/material/Chip';

interface ChipProps extends MUIChipProps {
	theme?: Theme;
}

export const StyledBreadcrumb = styled(Chip)<ChipProps>(({ theme }) => {
	const backgroundColor = theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800];
	return {
		backgroundColor,
		height: theme.spacing(3),
		color: theme.palette.text.primary,
		fontWeight: theme.typography.fontWeightRegular,
		'&:hover, &:focus': {
			backgroundColor: emphasize(backgroundColor, 0.06),
		},
		'&:active': {
			boxShadow: theme.shadows[1],
			backgroundColor: emphasize(backgroundColor, 0.12),
		},
	};
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591
