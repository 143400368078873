import { UnitOperationType, HeaderType, SimpleUnitOperationType } from 'models/types';
import { API_URL } from 'net/constants';

export const fetchAll = async (headers: HeaderType) => {
	const apiUrl = `${API_URL}/unit-operations`;
	const response = await fetch(apiUrl, { method: 'GET', headers })
		.then((result: Response) => result.json())
		.then((data: SimpleUnitOperationType[]) => data);

	return response;
};

export const fetchById = async (id: number, headers: HeaderType): Promise<UnitOperationType> => {
	const apiUrl = `${API_URL}/unit-operations/${id}`;
	return fetch(apiUrl, {
		method: 'GET',
		headers,
	})
		.then((result: Response) => result.json())
		.then((data: UnitOperationType) => data);
};
