import { FC, useState } from 'react';
// import { useSetRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useAuth0 } from '@auth0/auth0-react';
import { AppBarProps, Avatar, Divider, useTheme } from '@mui/material';
// import { globalSettingsOpenAtom } from 'store/atoms/globalSettingsOpen';
import { AppBarStyled } from './AppBar.style';
import CustomBreadcrumbs from './BreadCrumbs';
import GitRepoDialog from './GlobalSettingsDialog';

const CustomAppBar: FC<AppBarProps> = ({ ...props }) => {
	const { logout, user, isAuthenticated } = useAuth0();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	// const setGlobalSettingsOpen = useSetRecoilState(globalSettingsOpenAtom);

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	// const handleSettings = (event: React.MouseEvent<HTMLElement>) => {
	// 	event.preventDefault();
	// 	setGlobalSettingsOpen((current) => !current);
	// };

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogOut = () => {
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
		handleClose();
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBarStyled position="sticky" theme={theme} {...props}>
				<Toolbar
					sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: theme.palette.background.default }}
				>
					<CustomBreadcrumbs />
					<Box>
						{/* <Tooltip title="Global settings" placement="left">
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleSettings}
								color="inherit"
								disabled
							>
								{isAuthenticated && <SettingsIcon />}
							</IconButton>
						</Tooltip> */}
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit"
						>
							{isAuthenticated && <Avatar alt={user?.name} src={user?.picture} />}
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							{isAuthenticated && <MenuItem disabled>{user?.name}</MenuItem>}
							<Divider />
							{isAuthenticated && <MenuItem onClick={handleLogOut}>Log out</MenuItem>}
						</Menu>
						<GitRepoDialog />
					</Box>
				</Toolbar>
			</AppBarStyled>
		</Box>
	);
};

export default CustomAppBar;
