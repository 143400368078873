import { v4 as uuid } from 'uuid';
import { Node } from 'react-flow-renderer';
import { GridAlignment } from '@mui/x-data-grid';
import { UnitOperationInstanceValueType, UnitOperationInstanceType } from 'models/types';

export const getUniqueID = () => uuid();

export const findInputValueById = (id: number, inputValues: UnitOperationInstanceValueType[]) =>
	inputValues?.find((v) => v.fieldId === id);

export const addUnitOperationInstanceDataToNode = (
	nodes: Node<UnitOperationInstanceType>[],
	unitOperationInstanceData: UnitOperationInstanceType,
) => {
	return nodes.map((node) => {
		const latestNode = nodes[nodes.length - 1];
		if (node.id === latestNode?.id) {
			node.data = {
				...unitOperationInstanceData,
			};
			node.selectable = true;
		}
		return node;
	});
};

// Get a random pastel color
export const getRandomColor = () => {
	return `hsla(${360 * Math.random()}, ${55 + 70 * Math.random()}%, ${70 + 10 * Math.random()}%)`;
};

export const generateBCColumn = (
	field = '',
	headerName = '',
	type = 'number',
	editable = false,
	width = 150,
	sortable = false,
	headerAlign: GridAlignment = 'left',
	headerClassName = 'my-header',
) => ({
	field,
	headerName,
	type,
	width,
	editable,
	sortable,
	headerAlign,
	headerClassName,
});
