import { FC, Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import CustomAppBar from 'components/AppBar';

const ToolbarLayout: FC = () => {
	return (
		<Fragment>
			<CustomAppBar />
			<Outlet />
		</Fragment>
	);
};

export default ToolbarLayout;
