import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import ConstantTable from 'components/Drawers/RightDrawer/ConstantTable';
import ResultTable from 'components/Drawers/RightDrawer/ResultTable/ResultTable';
import InputsFields from '../Fields/InputsFields';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel({ children, value, index, ...rest }: TabPanelProps) {
	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			sx={{
				overflow: 'auto',
				height: 'calc(100% - 50px)',
			}}
			{...rest}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</Box>
	);
}

const CustomTabs = () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		event.preventDefault();
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%', height: 'calc(100vh - 140px)', overflow: 'hidden' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange} aria-label="Input and constants tabs" variant="fullWidth">
					<Tab label="Inputs" />
					{/* <Tab label="Constants" /> */}
					<Tab label="Result" />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<InputsFields />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<ConstantTable />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<ResultTable />
			</TabPanel>
		</Box>
	);
};

export default CustomTabs;
