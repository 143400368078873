import { plantQueries } from 'net/queries';
import { useEffect } from 'react';
import { generatePath, PathMatch } from 'react-router';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

const useGetPlantDataFromMatch = (path: string) => {
	const location = useLocation();
	const navigate = useNavigate();
	const match = matchPath({ path, end: true }, location.pathname) as PathMatch<string>;
	const { params } = match;
	const plantIdSplit = (params.plantId as string).split('-');
	const plantId = plantIdSplit[plantIdSplit.length - 1];
	let plantPath = match.pathname;

	const {
		data: plant,
		refetch: refetchPlant,
		isFetching: isFetchingPlant,
	} = plantQueries.usePlantById(plantId as string);

	if (plant?.name) {
		const plantNameSlug = `${plant.name.replace(/ /g, '-').toLocaleLowerCase()}-${plantId}`;
		plantPath = `/${generatePath(match.pattern.path, { ...match.params, plantId: plantNameSlug })}`;
	}

	useEffect(() => {
		navigate(plantPath);
	}, [navigate, plantPath]);

	return {
		plant,
		isFetchingPlant,
		refetchPlant,
	};
};

export default useGetPlantDataFromMatch;
