import { FC, Fragment, useEffect } from 'react';
import { Box, Fab, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { plantQueries } from 'net/queries';
import PlantCard from 'components/Plant/PlantCard';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { createPlantDialogOpen } from 'store/atoms/plants/createPlantDialog';
import PlantCardSkeleton from 'components/Plant/PlantCard/PlantCardSkeleton';
import { tempPlantAtom } from 'store/atoms/plants/tempPlant';

const Plants: FC = () => {
	const setCreatePlantDialogOpen = useSetRecoilState(createPlantDialogOpen);
	const resetTemplant = useResetRecoilState(tempPlantAtom);
	const { data: plants, isLoading, refetch: refetchPlants } = plantQueries.usePlants();

	const handlePlantCrudDialog = () => setCreatePlantDialogOpen(true);

	useEffect(() => {
		resetTemplant();
		if (!plants) refetchPlants();
	}, [plants, refetchPlants, resetTemplant]);

	return (
		<Fragment>
			<Box sx={{ maxWidth: 960, margin: '0px auto', paddingTop: '50px' }}>
				<Grid p={5} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
					{isLoading && (
						<Grid key="skeleton" item xs={2} sm={4} md={4}>
							<PlantCardSkeleton animation="wave" />
						</Grid>
					)}
					{plants?.map((plant) => (
						<Grid key={`plant_${plant.id}`} item xs={2} sm={4} md={4}>
							<PlantCard plant={plant} />
						</Grid>
					))}
				</Grid>
				{!plants?.length && !isLoading && (
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						sx={{ minHeight: '80vh' }}
					>
						<PlantCardSkeleton animation={false} />
						<Typography variant="h4" textAlign="center" style={{ fontWeight: '700' }}>
							No plant created yet!
						</Typography>
						<Typography variant="h6" textAlign="center" style={{ fontWeight: '300' }}>
							Click the green button on the bottom right to get started!
						</Typography>
					</Box>
				)}
			</Box>
			<Fab
				color="success"
				aria-label="add plant"
				sx={{ position: 'absolute', bottom: 16, right: 16 }}
				onClick={handlePlantCrudDialog}
			>
				<AddIcon />
			</Fab>
		</Fragment>
	);
};

export default Plants;
