import { UnitOperationInstanceType, HeaderType, UnitOperationInstanceCreateParamsType } from 'models/types';
import { API_URL } from 'net/constants';

export const create = async (
	data: UnitOperationInstanceCreateParamsType,
	headers: HeaderType,
): Promise<UnitOperationInstanceType> => {
	const { plantId, unitOperationInstance } = data;

	return fetch(`${API_URL}/plants/${plantId}/unit-operation-instance`, {
		method: 'POST',
		headers,
		body: JSON.stringify(unitOperationInstance),
	})
		.then((result: Response) => result.json())
		.then((data: UnitOperationInstanceType) => data);
};

export const fetchById = async (id: number, headers: HeaderType): Promise<UnitOperationInstanceType> => {
	const apiUrl = `${API_URL}/unit-operation-instance/${id}`;
	return fetch(apiUrl, {
		method: 'GET',
		headers,
	}).then((result) => result.json());
};

export const updatePosition = async (data: UnitOperationInstanceType, headers: HeaderType): Promise<Response> => {
	const apiUrl = `${API_URL}/unit-operation-instance/${data.id}/update-position`;

	return fetch(apiUrl, {
		method: 'PUT',
		headers,
		body: JSON.stringify(data),
	});
};
