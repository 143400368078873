import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientConfig, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import DebugObserver from 'store/debugging/RecoilDebugger';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';

// 1000 (ms) * 30 = 30 sec
const INTERVAL_IN_SECONDS = 1000 * 30;

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

const queryClientConfig: QueryClientConfig = {
	defaultOptions: {
		queries: {
			retry: 2,
			staleTime: INTERVAL_IN_SECONDS,
			cacheTime: INTERVAL_IN_SECONDS,
			refetchOnMount: 'always',
			refetchOnWindowFocus: true,
			refetchOnReconnect: 'always',
			refetchInterval: false,
			refetchIntervalInBackground: false,
			suspense: false,
		},
		mutations: {
			retry: 2,
		},
	},
};

const queryClient = new QueryClient(queryClientConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<StrictMode>
		<Auth0Provider
			domain={AUTH0_DOMAIN || ''}
			clientId={CLIENT_ID || ''}
			useRefreshTokens={true}
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: AUTH0_AUDIENCE,
			}}
		>
			<QueryClientProvider client={queryClient}>
				<RecoilRoot>
					<DebugObserver />
					<App />
					<ReactQueryDevtools initialIsOpen={false} />
				</RecoilRoot>
			</QueryClientProvider>
		</Auth0Provider>
	</StrictMode>,
);

reportWebVitals();
