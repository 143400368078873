import { useHeaders } from 'hooks/useHeaders';
import { EResponseStatus } from 'models/enums';
import { GlobalSettingsType, NotificationType } from 'models/types';
import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { notificationAtom } from 'store/atoms/notification';
import * as api from 'net/api';

export const useUpdateGlobalSettings = () => {
	const setNotification = useSetRecoilState(notificationAtom);
	const headers = useHeaders();

	return useMutation((data: GlobalSettingsType) => api.globalSettings.update(data, headers), {
		onSuccess: (data, variables) => {
			const payload: NotificationType = {
				message: 'The global settings have been updated!',
				status: EResponseStatus.SUCCESS,
			};

			setNotification(payload);
		},
		onError: (error, variables) => {
			const payload: NotificationType = {
				message: `ERROR: The global settings couldn't be updated!, ${error}`,
				status: EResponseStatus.ERRORED,
			};

			setNotification(payload);
		},
	});
};
