import React, { FC, useState, MouseEvent, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import GitHubIcon from '@mui/icons-material/GitHub';
import { TransitionProps } from '@mui/material/transitions';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Button, Chip, DialogActions, Divider, Grid, InputAdornment, useTheme } from '@mui/material';
import CustomTextField from 'components/shared/CustomTextField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { globalSettingsOpenAtom } from 'store/atoms/globalSettingsOpen';
import { useGlobalSettings } from 'net/queries/globalSettings';
import { useUpdateGlobalSettings } from 'net/mutations/globalSettings';
import { GitRepoType, GlobalSettingsType, NotificationType } from 'models/types';
import { EResponseStatus } from 'models/enums';
import { notificationAtom } from 'store/atoms/notification';
import { globalSettingsValidationSchema } from 'validation';
import { ValidationError } from 'yup';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const GlobalSettingsDialog: FC = () => {
	const theme = useTheme();
	const setNotification = useSetRecoilState(notificationAtom);
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [open, setOpen] = useRecoilState(globalSettingsOpenAtom);
	const { data: updatedGlobalSettings, mutate: updateGlobalSettings } = useUpdateGlobalSettings();
	const { data: globalSettings, refetch: refetchGlobalSettings } = useGlobalSettings();
	const [tempGitRepo, setTempGitRepo] = useState<GitRepoType>();

	useEffect(() => {
		const { url, publicSSH } = globalSettings?.gitRepo || {};
		setTempGitRepo({
			url: url || '',
			publicSSH: publicSSH || '',
		});
	}, [globalSettings]);

	useEffect(() => {
		if (updatedGlobalSettings) refetchGlobalSettings();
	}, [refetchGlobalSettings, updatedGlobalSettings]);

	const handleOpen = (value: boolean) => {
		setOpen(value);
		if (errors.length) setErrors([]);
	};

	const handleValueOnChange = (value: string) => {
		if (errors.length) setErrors([]);
		setTempGitRepo({
			url: value,
			publicSSH: '',
		});
	};

	const handleCopyToClipboard = (value: string) => {
		const { publicSSH } = tempGitRepo || {};

		try {
			navigator.clipboard.writeText(publicSSH || '');
			const payload: NotificationType = {
				message: 'The publicSSH has been copied succesfully to the clipboard!',
				status: EResponseStatus.SUCCESS,
			};
			setNotification(payload);
		} catch (error) {}
	};

	const handleOnSave = (event: MouseEvent) => {
		event.preventDefault();

		const globalSettings = {
			gitRepo: tempGitRepo,
		};

		globalSettingsValidationSchema
			.validate(globalSettings, { abortEarly: false })
			.then((data: GlobalSettingsType) => {
				updateGlobalSettings(data);
			})
			.catch((validationErrors: ValidationError) => {
				setErrors(validationErrors.inner);
			});
	};

	const errorByKey = (key: string): ValidationError | null => {
		return errors.find((error) => error.path === key) || null;
	};

	return (
		<Dialog fullScreen open={open} onClose={() => handleOpen(false)} TransitionComponent={Transition}>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar sx={{ display: 'flex', backgroundColor: theme.palette.background.default }}>
					<IconButton edge="start" color="inherit" onClick={() => handleOpen(false)} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
						Global settings
					</Typography>
				</Toolbar>
			</AppBar>
			<Grid container spacing={2} sx={{ p: 3 }}>
				<Grid item xs={12}>
					<Divider textAlign="center" sx={{ mb: 1 }}>
						<Chip label="Github repo" icon={<GitHubIcon />} />
					</Divider>
				</Grid>
				<Grid item xs={6}>
					<CustomTextField
						label="Github repository"
						fullWidth
						value={tempGitRepo?.url || ''}
						InputProps={{
							readOnly: true,
						}}
						error={!!errorByKey('gitRepo.url')}
						helperText={errorByKey('gitRepo.url')?.message}
						onChange={(event) => handleValueOnChange(event.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<CustomTextField
						label="Public SSH key"
						fullWidth
						value={tempGitRepo?.publicSSH || ''}
						InputProps={{
							readOnly: true,
							endAdornment: (
								<InputAdornment
									position="end"
									onClick={() => handleCopyToClipboard(globalSettings?.gitRepo.publicSSH || '')}
									sx={{ cursor: 'pointer' }}
								>
									<ContentCopyIcon />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>
			<DialogActions sx={{ p: 3 }}>
				<Button disabled color="success" onClick={handleOnSave}>
					Save
				</Button>
				<Button color="warning" onClick={() => handleOpen(false)}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default GlobalSettingsDialog;
