import { FC } from 'react';
// import { useRecoilSnapshot } from 'recoil';

// Observing All State Changes
// Reaon why this component is commented out https://github.com/facebookexperimental/Recoil/discussions/1888 Issue with this DebugObserver
const DebugObserver: FC = () => {
	// const snapshot = useRecoilSnapshot();
	// useEffect(() => {
	// 	console.debug('Changed Atoms:');
	// 	for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
	// 		console.debug(node.key, snapshot?.getLoadable(node));
	// 	}
	// }, [snapshot]);
	return null;
};

export default DebugObserver;
