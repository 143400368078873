import { HeaderType, PlantWithUnitOperationInstancesType, SimplePlantType } from 'models/types';
import { API_URL } from 'net/constants';

const PLANTS_ENDPOINT = `${API_URL}/plants`;

export const fetchAll = async (headers: HeaderType): Promise<SimplePlantType[]> => {
	const result = await fetch(PLANTS_ENDPOINT, { method: 'GET', headers })
		.then((result: Response) => result.json())
		.then((data: PlantWithUnitOperationInstancesType[]) => data);
	return result;
};

export const fetchById = async (plantId: number, headers: HeaderType): Promise<PlantWithUnitOperationInstancesType> => {
	const result = await fetch(`${PLANTS_ENDPOINT}/${plantId}`, { method: 'GET', headers })
		.then((result: Response) => result.json())
		.then((data: PlantWithUnitOperationInstancesType) => data);

	return result;
};

export const create = async (data: SimplePlantType, headers: HeaderType): Promise<SimplePlantType> => {
	const result = await fetch(PLANTS_ENDPOINT, {
		method: 'POST',
		headers,
		body: JSON.stringify(data),
	})
		.then((result: Response) => result.json())
		.then((data: SimplePlantType) => data);
	return result;
};

export const update = async (plant: SimplePlantType, headers: HeaderType): Promise<SimplePlantType> => {
	const result = await fetch(`${PLANTS_ENDPOINT}/${plant.id}`, {
		method: 'PUT',
		headers,
		body: JSON.stringify(plant),
	})
		.then((result: Response) => result.json())
		.then((data: SimplePlantType) => data);

	return result;
};

export const remove = async (id: number, headers: HeaderType): Promise<number> => {
	const result = await fetch(`${PLANTS_ENDPOINT}/${id}`, {
		method: 'DELETE',
		headers,
	})
		.then((result: Response) => result.json())
		.then((data: number) => data);

	return result;
};
