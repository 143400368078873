import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { BCSourceTypeAtom } from 'store/atoms/plants/BCSourceType';
import useBCGrid from 'hooks/useBCGrid';
import { Box, Skeleton } from '@mui/material';
import { GridCellEditStopParams, GridValidRowModel, MuiEvent } from '@mui/x-data-grid';
import { teal } from '@mui/material/colors';
import { useSourceTypeById } from 'net/queries/boundariesConditions';
import { StripedDataGrid } from './BCGrid.styles';
import BCCustomColumnMenu from './BCCustomColumnMenu';
import { BCSourceType } from './Data/BCSourceTypeData';
import BCHeaderRenameDialog from './BCHeaderRenameDialog';

const LoadingSkeleton: FC = () => {
	return (
		<Box sx={{ height: 'max-content' }}>
			{[...Array(10)].map((_, index) => (
				<Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} key={index} />
			))}
		</Box>
	);
};

const BCGrid: FC = () => {
	const selectedBCSourceTypeId = useRecoilValue(BCSourceTypeAtom);
	const { data: sourceType } = useSourceTypeById(selectedBCSourceTypeId);
	const { rows, columns } = useBCGrid(sourceType as unknown as BCSourceType);

	return (
		<div style={{ display: 'flex', height: 'calc(100vh - 150px)', width: '100%' }}>
			<Box
				sx={{
					flexGrow: 1,
					'& .my-header': {
						backgroundColor: teal[400],
					},
				}}
			>
				<StripedDataGrid
					columns={columns}
					rows={rows}
					disableColumnSelector
					hideFooter
					showColumnRightBorder
					showCellRightBorder
					disableSelectionOnClick
					disableExtendRowFullWidth
					components={{ LoadingOverlay: LoadingSkeleton, ColumnMenu: BCCustomColumnMenu }}
					experimentalFeatures={{ newEditingApi: true }}
					onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
						// if (params.reason === GridCellEditStopReasons.cellFocusOut) {
						// 	event.defaultMuiPrevented = true;
						// }
						console.log(params);
					}}
					getRowId={(row: GridValidRowModel) => row.id}
					getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
				/>
				<BCHeaderRenameDialog />
			</Box>
		</div>
	);
};

export default BCGrid;
