import { HeaderType } from 'models/types';
import { useRecoilValue } from 'recoil';
import { auth0TokenAtom } from 'store/atoms/auth0Token';

export function useHeaders(): HeaderType {
	const token = useRecoilValue(auth0TokenAtom);

	return {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	};
}
