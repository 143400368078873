import { EResponseStatus } from 'models/enums';
import { NotificationType } from 'models/types';
import { SetterOrUpdater } from 'recoil';

export const OnError = (message: string, setNotification: SetterOrUpdater<NotificationType | null>) => {
	const payload: NotificationType = {
		message: message,
		status: EResponseStatus.ERRORED,
	};

	setNotification(payload);
};
