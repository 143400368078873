import { Theme } from '@mui/material';
import { DRAWER_WIDTH } from 'components/Drawers/Drawers.style';
import { UnitOperationInstanceType } from 'models/types';
import { useNodes } from 'react-flow-renderer';
import { useRecoilValue } from 'recoil';
import { leftDrawerOpenAtom } from 'store/atoms/drawer/leftDrawerOpen';

export const useComponentStylesBasesOnDrawerStatus = (theme: Theme) => {
	const leftDrawerIsOpen = useRecoilValue(leftDrawerOpenAtom);
	const selectedNode = useNodes<UnitOperationInstanceType>().find((node) => node.selected);
	const CLOSED_LEFT_DRAWER_WIDTH = 55;

	let minusWidth = 0;
	let marginLeft = 0;
	let marginRight = 0;

	if (leftDrawerIsOpen) {
		minusWidth += DRAWER_WIDTH;
		marginLeft += DRAWER_WIDTH;
	} else {
		minusWidth += CLOSED_LEFT_DRAWER_WIDTH;
		marginLeft += CLOSED_LEFT_DRAWER_WIDTH;
	}

	if (!!selectedNode) {
		minusWidth += DRAWER_WIDTH;
		marginRight += DRAWER_WIDTH;
	} else {
		minusWidth += 0;
		marginRight += 0;
	}

	return {
		width: `calc(100% - ${minusWidth}px)`,
		marginLeft: `${marginLeft}px`,
		marginRight: `${marginRight}px`,
		transition: theme.transitions.create(['all'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.shortest,
		}),
	};
};
