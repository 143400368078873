import { useEffect } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GridRowModel } from '@mui/x-data-grid';
import { BCSourceType, IDType } from 'components/BCGrid/Data/BCSourceTypeData';
import { sourceColumnsAtom } from 'store/atoms/boundConditions/sourceColumns';
import { sourceRowsAtom } from 'store/atoms/boundConditions/sourceRows';
import { generateBCColumn } from 'utils/helpers';
import { useColumnsBySourceTypeId } from 'net/queries/boundariesConditions';

const useBCGrid = (sourceType?: BCSourceType) => {
	const { data: columnsBySourceType } = useColumnsBySourceTypeId(sourceType?.id as IDType);
	const [rows, setRows] = useRecoilState(sourceRowsAtom);
	const [columns, setColumns] = useRecoilState(sourceColumnsAtom);
	const resetRows = useResetRecoilState(sourceRowsAtom);
	const resetColumns = useResetRecoilState(sourceColumnsAtom);

	useEffect(() => {
		if (sourceType) {
			const { fields, name } = sourceType || {};

			const loweredCaseName = name.toLocaleLowerCase();

			const defaultRows: GridRowModel[] = fields?.map((field) => {
				const { id, name: fieldName } = field;
				let defaultColumnValues = {
					id,
					[`${loweredCaseName}`]: fieldName,
				};

				columnsBySourceType?.forEach((sourceType) => {
					return sourceType.values.forEach((v) => {
						if (v.name === field.name) {
							defaultColumnValues = {
								...defaultColumnValues,
								[`${sourceType.name}`]: v.value,
							};
						}
					});
				});

				return defaultColumnValues;
			});

			setRows(defaultRows);

			const otherColumns = columnsBySourceType?.map((sourceType) =>
				generateBCColumn(sourceType.name.toLocaleLowerCase(), sourceType.name, 'number', true),
			);

			setColumns((currentColumns) => {
				const collumns = [
					...currentColumns,
					{ ...generateBCColumn(loweredCaseName, name, 'string') },
					...(otherColumns || []),
				];

				return collumns;
			});

			return () => {
				resetRows();
				resetColumns();
			};
		}
	}, [columnsBySourceType, sourceType, resetColumns, resetRows, setColumns, setRows]);

	return { rows, columns };
};

export default useBCGrid;
