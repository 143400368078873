import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactFlowProvider } from 'react-flow-renderer';
import useWebSocket from 'hooks/useWebSocket';
import PageRoutes from 'routes';
import PlantCrudDialog from 'components/Plant/PlantCreateDialog';
import CustomSnackbar from 'components/shared/CustomSnackbar';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { auth0TokenAtom } from 'store/atoms/auth0Token';
import { BrowserRouter } from 'react-router-dom';
import { Box } from '@mui/material';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const App = () => {
	const BACKEND_URL_NO_PROTOCOL = BACKEND_URL?.replace(/^https?:/, '');
	const WS_URL = `ws${window.location.protocol === 'https:' ? 's' : ''}:${BACKEND_URL_NO_PROTOCOL}/notifications`;
	const [localToken, setLocalToken] = useRecoilState(auth0TokenAtom);
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		(async () => {
			try {
				const token = await getAccessTokenSilently();
				setLocalToken(token);
			} catch (error) {
				console.error(error);
			}
		})();
	}, [getAccessTokenSilently, setLocalToken]);

	useWebSocket(WS_URL);

	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

	const theme = useMemo(
		() =>
			createTheme({
				palette: {
					mode: prefersDarkMode ? 'dark' : 'light',
				},
			}),
		[prefersDarkMode],
	);

	if (localToken === '' || !localToken) return null;

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<BrowserRouter>
				<ReactFlowProvider>
					<Box
						sx={{
							width: '100%',
							height: '100%',
							backgroundColor: prefersDarkMode ? theme.palette.action.selected : theme.palette.background.default,
						}}
					>
						<PageRoutes />
						<PlantCrudDialog />
						<CustomSnackbar
							autoHideDuration={5000}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
						/>
					</Box>
				</ReactFlowProvider>
			</BrowserRouter>
		</ThemeProvider>
	);
};

export default withAuthenticationRequired(App);
