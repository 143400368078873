import { FC, useEffect, useRef, useState, MouseEvent } from 'react';
import { Popper, Grow, Paper, ClickAwayListener, MenuList, IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { SimplePlantType } from 'models/types';
import { plantMutations } from 'net/mutations';

type Props = {
	plant: SimplePlantType;
};

const PlantCardMenu: FC<Props> = ({ plant }) => {
	const { mutate: deletePlant } = plantMutations.useDeletePlant();
	const [openMenu, setOpenMenu] = useState(false);
	const anchorRef = useRef<HTMLButtonElement>(null);

	const handleToggle = (event: MouseEvent) => {
		event.stopPropagation();
		setOpenMenu((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event | React.SyntheticEvent) => {
		event.stopPropagation();
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpenMenu(false);
	};

	const handleDelete = (event: MouseEvent) => {
		event.stopPropagation();
		if (!plant.id) return;
		handleClose(event);
		deletePlant(plant.id);
	};

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpenMenu(false);
		} else if (event.key === 'Escape') {
			setOpenMenu(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(openMenu);

	useEffect(() => {
		if (prevOpen.current === true && openMenu === false) {
			anchorRef.current?.focus();
		}

		prevOpen.current = openMenu;
	}, [openMenu]);

	return (
		<div>
			<IconButton ref={anchorRef} aria-label="menu" onClick={handleToggle}>
				<MoreVertIcon />
			</IconButton>
			<Popper open={openMenu} anchorEl={anchorRef.current} role={undefined} placement="right-start" transition>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'right-start' ? 'left top' : 'left bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={openMenu}
									id="composition-menu"
									aria-labelledby="composition-button"
									onKeyDown={handleListKeyDown}
								>
									<MenuItem onClick={handleDelete}>
										<DeleteIcon sx={{ marginRight: 2 }} /> Delete
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
};

export default PlantCardMenu;
