import { FC, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectProps, Box } from '@mui/material';
import { FieldType } from 'models/types';

type Props = SelectProps & Partial<FieldType> & { values: string[] };

const CustomSelect: FC<Props> = ({ label, defaultValue, values, nullable, ...props }) => {
	const [open, setOpen] = useState(false);

	if (!label) return null;

	const handleOpenStatus = (value: boolean) => {
		setOpen(value);
	};

	return (
		<Box sx={{ marginBottom: 2 }}>
			<FormControl fullWidth>
				<InputLabel size="small" id="controlled-open-select-label">
					{label}
				</InputLabel>
				<Select
					labelId="controlled-open-select-label"
					id="controlled-open-select"
					open={open}
					onClose={() => handleOpenStatus(false)}
					onOpen={() => handleOpenStatus(true)}
					defaultValue={defaultValue}
					label={label}
					size="small"
					{...props}
				>
					{nullable && (
						<MenuItem value="None">
							<em>None</em>
						</MenuItem>
					)}
					{values.map((value, index) => (
						<MenuItem key={`item_${value}_${index}`} value={value}>
							{value}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default CustomSelect;
