import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import * as api from 'net/api';
import { EResponseStatus } from 'models/enums';
import { NotificationType, SimplePlantType } from 'models/types';
import { notificationAtom } from 'store/atoms/notification';
import { useHeaders } from 'hooks/useHeaders';
import { tempPlantAtom } from 'store/atoms/plants/tempPlant';
import { OnError } from './helpers';

enum RequestType {
	CREATE,
	UPDATE,
	DELETE,
}

const OnSuccess = (
	data: SimplePlantType,
	message: string,
	type: RequestType,
	queryClient: QueryClient,
	setNotification: SetterOrUpdater<NotificationType | null>,
) => {
	const payload: NotificationType = {
		message,
		status: EResponseStatus.SUCCESS,
	};

	let oldPlantList = queryClient.getQueryState('plants')?.data as SimplePlantType[];

	switch (type) {
		case RequestType.CREATE:
			oldPlantList.push(data);
			break;
		case RequestType.UPDATE:
			oldPlantList = [...oldPlantList].map((plant) => (plant.id === data.id ? data : plant));
			break;
		case RequestType.DELETE:
			oldPlantList = [...oldPlantList].filter((plant) => plant.id !== data.id);
			break;
		default:
			throw new Error(`Request type ${type} not found`);
	}

	queryClient.setQueryData(['plants'], oldPlantList);

	setNotification(payload);
};

export const useCreatePlant = () => {
	const queryClient = useQueryClient();
	const setNotification = useSetRecoilState(notificationAtom);
	const headers = useHeaders();

	return useMutation((data: SimplePlantType) => api.plant.create(data, headers), {
		onSuccess: (data) =>
			OnSuccess(
				data,
				`The plant ${data.name} has been created successfully!`,
				RequestType.CREATE,
				queryClient,
				setNotification,
			),
		onError: (error, variables) =>
			OnError(`ERROR: The plant ${variables.name} couldn't be created, ${error}`, setNotification),
	});
};

export const useUpdatePlant = () => {
	const queryClient = useQueryClient();
	const setNotification = useSetRecoilState(notificationAtom);
	const headers = useHeaders();

	return useMutation((plant: SimplePlantType) => api.plant.update(plant, headers), {
		onSuccess: (data, variables) =>
			OnSuccess(
				data,
				`The plant ${variables.name} has been updated successfully!`,
				RequestType.UPDATE,
				queryClient,
				setNotification,
			),
		onError: (error, variables) =>
			OnError(`ERROR: The plant ${variables.name} couldn't be updated, ${error}`, setNotification),
	});
};

export const useDeletePlant = () => {
	const tempPlant = useRecoilValue(tempPlantAtom);
	const queryClient = useQueryClient();
	const setNotification = useSetRecoilState(notificationAtom);
	const headers = useHeaders();

	return useMutation((plantId: number) => api.plant.remove(plantId, headers), {
		onSuccess: (id) =>
			OnSuccess(
				{
					...tempPlant,
					id,
				},
				`The plant with id: ${id} has been deleted successfully!`,
				RequestType.DELETE,
				queryClient,
				setNotification,
			),
		onError: (error, variables) =>
			OnError(`ERROR: The plant with id ${variables} couldn't be deleted, ${error}`, setNotification),
	});
};
