import { atom } from 'recoil';
import { SimplePlantType } from 'models/types';
import { ECurrency, ELanguage, EUnits } from 'models/enums';

const INITIAL_PLANT_DATA: SimplePlantType = {
	name: '',
	owner: '',
	createdBy: '',
	location: '',
	plantSettings: {
		description: '',
		currency: ECurrency.EURO,
		language: ELanguage.ENGLISH,
		unitsType: EUnits.METRIC,
	},
};

export const tempPlantAtom = atom<SimplePlantType>({
	key: 'tempPlantAtom',
	default: INITIAL_PLANT_DATA,
});
