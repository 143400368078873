import { FC, MouseEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { MenuList } from '@mui/material';
import { GridColumnMenuProps } from '@mui/x-data-grid';
import { useAddColumn, useDeleteColumn } from 'net/mutations/boundariesConditions';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { sourceHeaderToRenameAtom } from 'store/atoms/boundConditions/sourceHeaderToRename';
import { BCSourceTypeAtom } from 'store/atoms/plants/BCSourceType';
import { BC_SOURCE_TYPE_LIST_DATA } from './Data/BCSourceTypeData';

const BCCustomColumnMenu: FC<GridColumnMenuProps> = ({ hideMenu, currentColumn }) => {
	const { mutate: addColumn } = useAddColumn();
	const { mutate: deleteColumn } = useDeleteColumn();
	const selectedBCSourceTypeId = useRecoilValue(BCSourceTypeAtom);
	const setColumnHeaderToBeRenamed = useSetRecoilState(sourceHeaderToRenameAtom);
	const selectedSourceType = BC_SOURCE_TYPE_LIST_DATA.find((sourceType) => sourceType.id === selectedBCSourceTypeId);
	const menuItemDisabled = currentColumn.field === selectedSourceType?.name.toLocaleLowerCase();
	const isCurrent = currentColumn.field === 'current';

	const onRename = (event: MouseEvent) => {
		hideMenu(event);
		setColumnHeaderToBeRenamed(currentColumn);
	};

	const onAdd = (event: MouseEvent) => {
		hideMenu(event);
		addColumn(currentColumn);
	};

	const onDelete = (event: MouseEvent) => {
		hideMenu(event);
		deleteColumn(currentColumn);
	};

	return (
		<MenuList>
			<MenuItem disabled={menuItemDisabled || isCurrent} onClick={onRename}>
				Rename
			</MenuItem>
			<MenuItem disabled={menuItemDisabled} onClick={onAdd}>
				Add New
			</MenuItem>
			<MenuItem disabled={menuItemDisabled || isCurrent} onClick={onDelete}>
				Delete source
			</MenuItem>
		</MenuList>
	);
};

export default BCCustomColumnMenu;
