import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { SimplePlantType } from 'models/types';
import { getRandomColor } from 'utils/helpers';
import PlantCardMenu from './PlantCardMenu';

type Props = {
	plant: SimplePlantType;
};

const PlantCard: FC<Props> = ({ plant }) => {
	const { name, createdBy, createdAt } = plant;
	const [raised, setRaised] = useState<boolean>(false);
	const [avatarColor, setAvatarColor] = useState<string>();
	const dateCreated = new Date(createdAt as Date).toLocaleDateString();
	const navigate = useNavigate();

	useEffect(() => {
		if (!plant) return;
		const color = getRandomColor();
		setAvatarColor(color);
	}, [plant]);

	if (!plant) return null;

	const goToPlantPage = () => {
		navigate(`/plants/${plant.id}`);
	};

	const toggleRaised = () => {
		setRaised((current) => !current);
	};

	return (
		<Card
			sx={{
				maxWidth: 345,
				cursor: 'pointer',
				':hover': {
					pointer: 20, // theme.shadows[20]
				},
			}}
			raised={raised}
			onClick={goToPlantPage}
			onMouseOver={toggleRaised}
			onMouseOut={toggleRaised}
		>
			<CardHeader
				avatar={
					<Avatar sx={{ bgcolor: avatarColor }} aria-label="recipe">
						{Array.from(name)[0]}
					</Avatar>
				}
				action={<PlantCardMenu plant={plant} />}
				title={name}
				subheader={dateCreated}
			/>
			<CardContent>
				<Typography variant="body2" color="text.primary">
					Creator: {createdBy}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default PlantCard;
