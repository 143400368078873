import { FC } from 'react';
import { useRecoilState } from 'recoil';
import { GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import IconButton from '@mui/material/IconButton';
import { tableDialogOpenAtom } from 'store/atoms/tableDialog/tableDialog';

const CustomGridToolbar: FC = () => {
	const [tableDialogOpen, setTableDialogOpen] = useRecoilState(tableDialogOpenAtom);

	const handleDialogOpen = () => {
		setTableDialogOpen(true);
	};

	return (
		<GridToolbarContainer>
			<GridToolbarDensitySelector />
			<GridToolbarExport />
			{!tableDialogOpen && (
				<IconButton onClick={handleDialogOpen}>
					<AspectRatioIcon />
				</IconButton>
			)}
		</GridToolbarContainer>
	);
};

export default CustomGridToolbar;
