import { GridColDef } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/models/api/gridApiCommunity';
import { ColumnType, SOURCE_COLUMNS, ValueType } from 'components/BCGrid/Data/BCSourcesData';
import { BC_SOURCE_TYPE_LIST_DATA, IDType } from 'components/BCGrid/Data/BCSourceTypeData';
import { HeaderType } from 'models/types';
import { getUniqueID } from 'utils/helpers';

export const fetchAllSourceTypes = (headers: HeaderType) => {
	return BC_SOURCE_TYPE_LIST_DATA;
};

export const fetchSourceTypeById = (id: IDType, headers: HeaderType) => {
	return BC_SOURCE_TYPE_LIST_DATA.find((sourceType) => sourceType.id === id);
};

export const fetchColumnsBySourceTypeId = (sourceTypeId: IDType, headers: HeaderType) => {
	return SOURCE_COLUMNS[sourceTypeId];
};

export const addColumn = (
	column: GridColDef,
	sourceTypeId: IDType,
	apiRef: React.MutableRefObject<GridApiCommunity>,
	headers: HeaderType,
): Promise<ColumnType> => {
	const columnIndex = apiRef.current.getColumnIndex(column.field);
	const sourceType = BC_SOURCE_TYPE_LIST_DATA.find((sourceType) => sourceType.id === sourceTypeId);

	const newColumn = {
		id: columnIndex + 1,
		name: `${sourceType?.name}-${getUniqueID().split('-')[0]}`,
		values:
			sourceType?.fields.map((data, index) => ({
				id: index + 1,
				name: data.name,
				value: 0,
			})) || ([] as ValueType[]),
	};

	return Promise.resolve(newColumn);
};

export const deleteColumn = (
	column: GridColDef,
	apiRef: React.MutableRefObject<GridApiCommunity>,
	headers: HeaderType,
): Promise<number> => {
	const columnIndex = apiRef.current.getColumnIndex(column.field);

	return Promise.resolve(columnIndex);
};

export const renameColumn = (column: GridColDef, headers: HeaderType): Promise<GridColDef> => {
	return Promise.resolve(column);
};
