import { FC, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { ValidationError } from 'yup';
import { Button, Chip, Divider, Grid } from '@mui/material';
import CustomTextField from 'components/shared/CustomTextField';
import { plantMutations } from 'net/mutations';
import MapBoxGL from 'components/Mapbox';
import { tempPlantAtom } from 'store/atoms/plants/tempPlant';
import ContentWrapper from 'components/ContentWrapper';
import CustomSelect from 'components/shared/CustomSelect';
import { ECurrency, ELanguage, EUnits } from 'models/enums';
import { plantValidationSchema } from 'validation';
import useGetPlantDataFromMatch from 'hooks/useGetPlantDataFromMatch';

enum EFieldsKeyType {
	NAME = 'name',
	UNITS_TYPE = 'unitsType',
	DESCRIPTION = 'description',
	CURRENCY = 'currency',
	LANGUAGE = 'language',
	OWNER = 'owner',
}

const PlantSettings: FC = () => {
	const { plant: savedPlant } = useGetPlantDataFromMatch('plants/:plantId/settings');
	const [tempPlant, setTempPlant] = useRecoilState(tempPlantAtom);
	const { mutate: updatePlant } = plantMutations.useUpdatePlant();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	useEffect(() => {
		if (savedPlant) {
			const { unitOperationInstances, ...rest } = savedPlant;
			setTempPlant((current) => {
				return {
					...rest,
					plantSettings: savedPlant.plantSettings || current.plantSettings,
				};
			});
		}
	}, [savedPlant, setTempPlant]);

	const handleValueOnChange = (key: EFieldsKeyType, value: string) => {
		if (!tempPlant) return;
		const isPlantSettingsKey = Object.values(EFieldsKeyType)
			.filter((item) => item !== EFieldsKeyType.OWNER && item !== EFieldsKeyType.NAME)
			.includes(key);

		if (isPlantSettingsKey) {
			setTempPlant((current) => {
				return {
					...current,
					plantSettings: {
						...current.plantSettings,
						[`${key}`]: value,
					},
				};
			});
		} else {
			setTempPlant((current) => {
				return {
					...current,
					[`${key}`]: value,
				};
			});
		}
	};

	const onUpdatePlant = () => {
		plantValidationSchema
			.validate(tempPlant, { abortEarly: false })
			.then(() => {
				updatePlant(tempPlant);
			})
			.catch((validationErrors: ValidationError) => {
				setErrors(validationErrors.inner);
			});
	};

	const errorByKey = (key: string): ValidationError | null => {
		return errors.find((error) => error.path === key) || null;
	};

	return (
		<ContentWrapper padding={5}>
			<Grid container spacing={2} sx={{ marginBottom: 1 }}>
				<Grid item xs={6}>
					<Divider textAlign="left" sx={{ mb: 3 }}>
						<Chip label="Info" />
					</Divider>
					<CustomTextField
						required
						label="Plant name"
						fullWidth
						value={tempPlant?.name || ''}
						error={!!errorByKey(EFieldsKeyType.NAME)}
						helperText={errorByKey(EFieldsKeyType.NAME)?.message}
						onChange={(event) => handleValueOnChange(EFieldsKeyType.NAME, event.target.value)}
						sx={{ marginBottom: 2 }}
					/>
					<CustomTextField
						required
						label="Owner"
						fullWidth
						value={tempPlant?.owner || ''}
						error={!!errorByKey(EFieldsKeyType.OWNER)}
						helperText={errorByKey(EFieldsKeyType.OWNER)?.message}
						onChange={(event) => handleValueOnChange(EFieldsKeyType.OWNER, event.target.value)}
						sx={{ marginBottom: 2 }}
					/>
					<CustomSelect
						label="Currency"
						value={tempPlant.plantSettings?.currency || ''}
						values={Object.values(ECurrency)}
						error={!!errorByKey(EFieldsKeyType.CURRENCY)}
						onChange={(event) => handleValueOnChange(EFieldsKeyType.CURRENCY, event.target.value as ECurrency)}
						sx={{ marginBottom: 0.3 }}
					/>
					<CustomSelect
						label="Unit"
						value={tempPlant.plantSettings?.unitsType || ''}
						values={Object.values(EUnits)}
						error={!!errorByKey(EFieldsKeyType.UNITS_TYPE)}
						onChange={(event) => handleValueOnChange(EFieldsKeyType.UNITS_TYPE, event.target.value as EUnits)}
						sx={{ marginBottom: 0.3 }}
					/>
					<CustomSelect
						label="Language"
						value={tempPlant.plantSettings?.language || ''}
						values={Object.values(ELanguage)}
						error={!!errorByKey(EFieldsKeyType.LANGUAGE)}
						onChange={(event) => handleValueOnChange(EFieldsKeyType.LANGUAGE, event.target.value as ELanguage)}
						sx={{ marginBottom: 0.3 }}
					/>
					<CustomTextField
						label="Description"
						rows={5.85}
						multiline
						fullWidth
						value={tempPlant.plantSettings?.description || ''}
						error={!!errorByKey(EFieldsKeyType.DESCRIPTION)}
						helperText={errorByKey(EFieldsKeyType.DESCRIPTION)?.message}
						onChange={(event) => handleValueOnChange(EFieldsKeyType.DESCRIPTION, event.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Divider textAlign="left" sx={{ mb: 3 }}>
						<Chip label="Location" />
					</Divider>
					<MapBoxGL plantLocation={tempPlant?.location} />
				</Grid>
				<Grid item xs={12}>
					<Divider sx={{ mb: 3 }}>
						<Chip label="User + Permissions" />
					</Divider>
					<CustomSelect label="Users + permission" value={''} values={[]} error={false} sx={{ marginBottom: 0.3 }} />
				</Grid>
				<Grid container sx={{ mt: 2 }} justifyContent="end">
					<Button variant="outlined" color="success" size="large" onClick={onUpdatePlant}>
						Save
					</Button>
				</Grid>
			</Grid>
		</ContentWrapper>
	);
};

export default PlantSettings;
