import { useQuery } from 'react-query';
import * as api from 'net/api';
import { useHeaders } from 'hooks/useHeaders';
import { IDType } from 'components/BCGrid/Data/BCSourceTypeData';

export const useSourcesTypes = () => {
	const headers = useHeaders();

	return useQuery(['sourcesTypes'], () => api.boundariesConditions.fetchAllSourceTypes(headers));
};

export const useSourceTypeById = (sourceTypeId: IDType) => {
	const headers = useHeaders();

	return useQuery(['sourceType', sourceTypeId], () =>
		api.boundariesConditions.fetchSourceTypeById(sourceTypeId, headers),
	);
};

// Fetch (column) by the name
export const useColumnsBySourceTypeId = (sourceTypeId: IDType) => {
	const headers = useHeaders();

	return useQuery(['columnsBySourceType', sourceTypeId], () =>
		api.boundariesConditions.fetchColumnsBySourceTypeId(sourceTypeId, headers),
	);
};
