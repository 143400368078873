import { DragEvent, MouseEvent, FC, useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { grey } from '@mui/material/colors';
import 'react-flow-renderer/dist/style.css';
import 'react-flow-renderer/dist/theme-default.css';
import ReactFlow, {
	Node,
	addEdge,
	Background,
	useNodesState,
	useEdgesState,
	MiniMap,
	Controls,
	Connection,
	BackgroundVariant,
	ReactFlowInstance,
	FitViewOptions,
} from 'react-flow-renderer';
import { UnitOperationType, UnitOperationInstanceType } from 'models/types';
import { unitOperationInstanceMutations } from 'net/mutations';
import useGetPlantDataFromMatch from 'hooks/useGetPlantDataFromMatch';
import UnitOperationInstanceNode from 'components/FlowNodes/UnitOperationInstanceNode';

export const edgeOptions = {
	animated: true,
	type: 'smoothstep',
	style: {
		stroke: '2px solid white',
	},
	markerStart: 'arrow',
};

export const fitViewOptions: FitViewOptions = {
	padding: 0.2,
};

const VariantsDiagram: FC = () => {
	const { plant, refetchPlant } = useGetPlantDataFromMatch('plants/:plantId/variants');

	const reactFlowWrapper = useRef<HTMLDivElement>(null);
	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();

	const { mutate: updateUnitOperationInstancePosition } = unitOperationInstanceMutations.useUpdatePos();

	const { data: createdUnitOperationInstance, mutate: createUnitOperationInstance } =
		unitOperationInstanceMutations.useCreate();

	const nodeTypes = useMemo(() => ({ unitOperationInstanceNode: UnitOperationInstanceNode }), []);

	useEffect(() => {
		if (createdUnitOperationInstance) refetchPlant();
	}, [createdUnitOperationInstance, refetchPlant]);

	useEffect(() => {
		plant?.unitOperationInstances.forEach((unitOperationInstance) => {
			const { id, x, y } = unitOperationInstance;
			const node: Node<UnitOperationInstanceType> = {
				id: id.toString(),
				position: { x, y },
				type: 'unitOperationInstanceNode',
				data: unitOperationInstance,
				selectable: true,
			};
			setNodes((currentNodes) => currentNodes.concat([{ ...node }]));
		});
	}, [plant?.unitOperationInstances, setNodes]);

	const onNodeDragStop = (event: MouseEvent, node: Node<UnitOperationInstanceType>) => {
		event.preventDefault();
		const { id } = node.data;
		const { x, y } = node.position;

		const newUnitOperationInstance: UnitOperationInstanceType = {
			...node.data,
			id,
			x,
			y,
		};

		updateUnitOperationInstancePosition(newUnitOperationInstance);
	};

	const onConnect = useCallback(
		(connection: Connection) => {
			setEdges((eds) => addEdge(connection, eds));
		},
		[setEdges],
	);

	const onDragOver = useCallback((event: DragEvent) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	}, []);

	const onDrop = useCallback(
		(event: DragEvent) => {
			event.preventDefault();

			if (!plant?.id) return;

			const jsonData = event.dataTransfer.getData('application/reactflow');
			if (!reactFlowWrapper.current || !reactFlowInstance || typeof jsonData === 'undefined' || !jsonData) return;

			const parsedTemplateUnitOperationData: UnitOperationType = JSON.parse(jsonData);
			const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

			const position = reactFlowInstance.project({
				x: event.clientX - reactFlowBounds.left,
				y: event.clientY - reactFlowBounds.top,
			});

			createUnitOperationInstance({
				plantId: +plant.id,
				unitOperationInstance: {
					id: -9999,
					unitOperationId: parsedTemplateUnitOperationData.id,
					x: position.x,
					y: position.y,
					inputValues: [],
					outputValues: [],
				},
			});
		},
		[createUnitOperationInstance, plant?.id, reactFlowInstance],
	);

	return (
		<div ref={reactFlowWrapper} style={{ width: '100vw', height: 'calc(100vh - 64px)' }}>
			<ReactFlow
				nodes={nodes}
				edges={edges}
				nodeTypes={nodeTypes}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				onNodeDragStop={onNodeDragStop}
				defaultEdgeOptions={edgeOptions}
				onConnect={onConnect}
				onInit={setReactFlowInstance}
				fitView
				fitViewOptions={fitViewOptions}
				defaultZoom={1}
				onDrop={onDrop}
				onDragOver={onDragOver}
				snapToGrid={true}
				snapGrid={[10, 10]}
				attributionPosition="bottom-left"
				selectionKeyCode={null}
				deleteKeyCode={['Backspace', 'Delete']}
			>
				<MiniMap />
				<Controls />
				<Background variant={BackgroundVariant.Dots} gap={20} size={1} style={{ backgroundColor: grey[800] }} />
			</ReactFlow>
		</div>
	);
};

export default VariantsDiagram;
