import { useQuery } from 'react-query';
import * as api from 'net/api';
import { useHeaders } from 'hooks/useHeaders';

export const useUnitOperations = () => {
	const headers = useHeaders();
	return useQuery(['unitOperations'], () => api.unitOperation.fetchAll(headers));
};

export const useUnitOperationById = (id: number) => {
	const headers = useHeaders();

	return useQuery(['unitOperationById', id], () => api.unitOperation.fetchById(id, headers), {
		enabled: id > 0,
	});
};
