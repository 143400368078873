export enum ENodes {
	UNIT_OPERATION = 'UNIT OPERATION',
}

export enum EHandles {
	SOURCE = 'source',
	TARGET = 'target',
}

export enum EField {
	INT = 'INT',
	FLOAT = 'FLOAT',
	TEXT = 'TEXT',
	CLASS = 'CLASS',
	TOGGLE = 'TOGGLE',
	LIST = 'LIST',
}

export enum EScriptObject {
	FUNCTION = 'FUNCTION',
	CLASS = 'CLASS',
	DICTIONARY = 'DICTIONARY',
	ENUMERATION = 'ENUMERATION',
	FLOW_TYPE = 'FLOW TYPE',
	UNIT_OPERATION = 'UNIT OPERATION',
	SCENARIO_SETTINGS = 'SCENARIO_SETTINGS',
}

export enum EResponseStatus {
	SUCCESS = 'success',
	ERRORED = 'error',
	WARNING = 'warning',
	INFO = 'info',
}

export enum EUnits {
	METRIC = 'METRIC',
	IMPERIAL = 'IMPERIAL',
}

export enum ELanguage {
	ENGLISH = 'English',
	DUTCH = 'Dutch',
}

export enum ECurrency {
	EURO = 'Euro',
	DOLLAR = 'Dollar',
}
