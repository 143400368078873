import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { CSSProperties } from '@mui/styled-engine';

export const DRAWER_WIDTH = 240;

const openedMixin = (theme: Theme, width: CSSProperties['width']): CSSObject => ({
	width,
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.shortest,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme, width: CSSProperties['width']): CSSObject => ({
	width,
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.shortest,
	}),
	overflowX: 'hidden',
});

type DrawerHeaderStyledProps = {
	theme: Theme;
	flexDirection?: CSSProperties['flexDirection'];
};

const DrawerHeaderStyled = styled(Box)<DrawerHeaderStyledProps>(({ theme, flexDirection }) => ({
	display: 'flex',
	flexDirection,
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
}));

type DrawerStyledProps = {
	theme: Theme;
	open: boolean;
	width: CSSProperties['width'];
};

const DrawerStyled = styled(MuiDrawer)<DrawerStyledProps>(({ theme, open, width }) => ({
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme, width),
		'& .MuiDrawer-paper': openedMixin(theme, width),
	}),
	...(!open && {
		...closedMixin(theme, width),
		'& .MuiDrawer-paper': closedMixin(theme, width),
	}),
}));

export { DrawerHeaderStyled, DrawerStyled };
