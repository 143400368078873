import { FC, Fragment, ReactNode } from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuObjectType } from '../MenuTypes';

type Props = {
	data: MenuObjectType;
	isOpen: boolean;
	selected: boolean;
	children: ReactNode;
	callBack: () => void;
};

const CollapsibleMenuItem: FC<Props> = ({ isOpen, selected, callBack, data, children }) => {
	const { iconStart, visible } = data;
	const IconStart = iconStart || null;

	if (!visible) return null;

	return (
		<Fragment>
			<ListItemButton onClick={callBack} selected={selected}>
				{IconStart && (
					<ListItemIcon sx={{ minWidth: 40 }}>
						<IconStart />
					</ListItemIcon>
				)}
				<ListItemText primary={data.name} />
				{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</ListItemButton>
			{children}
		</Fragment>
	);
};

export default CollapsibleMenuItem;
