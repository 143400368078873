import { FC, MouseEvent } from 'react';
import Box from '@mui/material/Box';
import { ButtonGroup, Button } from '@mui/material';
// import { useRunCalculation } from 'net/mutations';
import { useNodesState, Node } from 'react-flow-renderer';
import { UnitOperationInstanceType } from 'models/types';
import UnitOperationFields from './UnitOperationFields';

const PADDING = 2.5;

const InputsFields: FC = () => {
	const [nodes, setNodes] = useNodesState<Node<UnitOperationInstanceType>[]>([]);
	const selectedNode = nodes.find((node) => node.selected);

	// const { mutate: runCalculation } = useRunCalculation();

	const handleRunCalculation = (event: MouseEvent) => {
		event.preventDefault();
		// runCalculation(selectedNode.data.id.toString());
	};

	const handleOnCancel = () => {
		setNodes((nds) =>
			nds.map((node) => {
				if (node.id === selectedNode?.id) {
					const { selected, ...newNode } = node;
					return newNode;
				}

				return node;
			}),
		);
	};

	return (
		<Box
			component="form"
			sx={{
				'& .MuiTextField-root': { my: 1.2, display: 'flex' },
			}}
			noValidate
			autoComplete="off"
		>
			<UnitOperationFields />
			<ButtonGroup
				sx={{ p: PADDING, position: 'absolute', bottom: 0, left: 0 }}
				disableElevation
				variant="outlined"
				fullWidth
			>
				<Button color="success" disabled={true} size="large" onClick={handleRunCalculation}>
					RUN
				</Button>
				<Button color="warning" size="large" onClick={handleOnCancel}>
					CANCEL
				</Button>
			</ButtonGroup>
		</Box>
	);
};

export default InputsFields;
