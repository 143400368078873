import { getUniqueID } from 'utils/helpers';
import { IDType, INFLUENT_DATA, WATER_DATA } from './BCSourceTypeData';

export type ColumnType = {
	id: IDType;
	name: string;
	values: ValueType[];
};

export type ValueType = {
	id: IDType;
	name: string;
	value: number;
};

const influent: ColumnType[] = [
	{
		id: 1,
		name: 'current',
		values: INFLUENT_DATA.fields.map((influent, index) => ({
			id: index,
			name: influent.name,
			value: 0,
		})),
	},
	{
		id: 2,
		name: `${INFLUENT_DATA.name}-${getUniqueID().split('-')[0]}`,
		values: INFLUENT_DATA.fields.map((influent, index) => ({
			id: index,
			name: influent.name,
			value: 0,
		})),
	},
];

const water: ColumnType[] = [
	{
		id: 3,
		name: 'current',
		values: WATER_DATA.fields.map((water, index) => ({
			id: index,
			name: water.name,
			value: 0,
		})),
	},
	{
		id: 4,
		name: `${WATER_DATA.name}1`,
		values: WATER_DATA.fields.map((water, index) => ({
			id: index,
			name: water.name,
			value: 0,
		})),
	},
];

type SourceColumnsType = Record<IDType, ColumnType[]>;

export const SOURCE_COLUMNS: SourceColumnsType = {
	1: influent,
	2: water,
};
