import { Typography } from '@mui/material';
import ContentWrapper from 'components/ContentWrapper';
import { FC } from 'react';

type Props = {
	title: string;
};

const PlantEmpty: FC<Props> = ({ title }) => {
	return (
		<ContentWrapper padding={5}>
			<Typography>{title}</Typography>
		</ContentWrapper>
	);
};

export default PlantEmpty;
