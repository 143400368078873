import { FC, useEffect } from 'react';
import { unitOperationQueries } from 'net/queries';
import { useSetRecoilState } from 'recoil';
import { unitOperationLibraryAtom } from 'store/atoms/unitOperations/unitOperationLibrary';
import VariantsDiagram from 'components/VariantsDiagram';

const Variants: FC = () => {
	const { data: unitOperations } = unitOperationQueries.useUnitOperations();
	const setUnitOperationLibrary = useSetRecoilState(unitOperationLibraryAtom);

	useEffect(() => {
		if (unitOperations) {
			setUnitOperationLibrary(unitOperations);
		}
	}, [unitOperations, setUnitOperationLibrary]);

	return <VariantsDiagram />;
};

export default Variants;
