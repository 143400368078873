import { FC, Fragment, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { FullscreenControl, GeolocateControl, NavigationControl, ScaleControl } from '@urbica/react-map-gl';
import Draw from '@urbica/react-map-gl-draw';
import { tempPlantAtom } from 'store/atoms/plants/tempPlant';

const CustomControls: FC = () => {
	const [mode] = useState('draw_point');
	const setTempPlant = useSetRecoilState(tempPlantAtom);

	const handleOnChange = (data: string) => {
		setTempPlant((current) => {
			return {
				...current,
				location: data,
			};
		});
	};

	return (
		<Fragment>
			<GeolocateControl position="top-right" />
			<FullscreenControl position="top-right" />
			<Draw
				mode={mode}
				position="top-right"
				displayControlsDefault={false}
				pointControl
				lineStringControl={false}
				combineFeaturesControl={false}
				uncombineFeaturesControl={false}
				polygonControl={false}
				onChange={(data: GeoJSON.FeatureCollection) => {
					handleOnChange(JSON.stringify(data.features[0]));
				}}
			/>
			<ScaleControl maxWidth={50} unit="metric" position="bottom-right" />
			<NavigationControl showZoom showCompass={false} position="bottom-right" />
		</Fragment>
	);
};

export default CustomControls;
