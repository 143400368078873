import { ECurrency, ELanguage, EUnits } from 'models/enums';
import * as Yup from 'yup';

export const plantValidationSchema = Yup.object().shape({
	id: Yup.number().notRequired().positive().integer(),
	name: Yup.string().required().min(4).max(100),
	owner: Yup.string().required().min(4).max(100),
	createdBy: Yup.string().required(),
	createdAt: Yup.date().notRequired(),
	updatedAt: Yup.date().notRequired(),
	plantSettings: Yup.object()
		.shape({
			id: Yup.number().notRequired().positive().integer(),
			description: Yup.string().notRequired().min(0).max(100),
			currency: Yup.mixed<ECurrency>().notRequired(),
			language: Yup.mixed<ELanguage>().notRequired(),
			unitsType: Yup.mixed<EUnits>().notRequired(),
		})
		.notRequired()
		.nullable(),
	location: Yup.string().required().min(10),
});

export const globalSettingsValidationSchema = Yup.object().shape({
	gitRepo: Yup.object()
		.shape({
			url: Yup.string()
				.required()
				.test('git-url-validation', 'Must be a github repo', (url) => {
					const regex = new RegExp(/((git|ssh|http(s)?)|(git@[\w.]+))(:(\/\/)?)([\w.@:/\-~]+)(\.git)(\/)?/);
					return regex.test(url || '');
				}),
			publicSSH: Yup.string().notRequired(),
		})
		.notRequired()
		.nullable(),
});
