import { FC } from 'react';
import styled from '@emotion/styled';
import { Box, BoxProps, Theme, useTheme } from '@mui/material';
import { useComponentStylesBasesOnDrawerStatus } from 'hooks/useComponentStylesBasesOnDrawerStatus';

interface ComponentProps extends BoxProps {
	theme: Theme;
}

const propsToNotForward = ['empty'];

export const ContentWrapperStyled = styled(Box, {
	shouldForwardProp: (prop) => !propsToNotForward.includes(prop),
})<ComponentProps>(({ theme }) => {
	return useComponentStylesBasesOnDrawerStatus(theme);
});

const ContentWrapper: FC<BoxProps> = ({ children, ...props }) => {
	const theme = useTheme();

	return (
		<Box sx={{ flexGrow: 1 }} {...props}>
			<ContentWrapperStyled theme={theme}>{children}</ContentWrapperStyled>
		</Box>
	);
};

export default ContentWrapper;
