import { FC, DragEvent, MouseEvent } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, SxProps, Theme } from '@mui/material';
import { SimpleUnitOperationType } from 'models/types';
import { MenuObjectType } from '../MenuTypes';

type Props = {
	data: SimpleUnitOperationType | MenuObjectType;
	selected: boolean;
	sx?: SxProps<Theme>;
	onClick?: (event: MouseEvent) => void;
};

const MenuItem: FC<Props> = ({ data, selected, sx, ...props }) => {
	const { name } = data;
	const IconStart = 'iconStart' in data ? data.iconStart : null;
	const IconEnd = 'iconEnd' in data ? data.iconEnd : null;
	const isDraggable = !('iconStart' in data);

	const onDragStart = (event: DragEvent<HTMLDivElement>, nodeType: string) => {
		event.dataTransfer.setData('application/reactflow', nodeType);
		event.dataTransfer.effectAllowed = 'move';
	};

	return (
		<div className="dndnode" onDragStart={(event) => onDragStart(event, JSON.stringify(data))} draggable={isDraggable}>
			<ListItemButton selected={selected} sx={{ ...sx }} {...props}>
				<ListItemIcon sx={{ minWidth: 40 }}>{IconStart && <IconStart />}</ListItemIcon>
				<ListItemText primary={name} />
				<ListItemIcon sx={{ minWidth: 20 }}>{IconEnd && <IconEnd />}</ListItemIcon>
			</ListItemButton>
		</div>
	);
};

export default MenuItem;
