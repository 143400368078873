import { FC, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import CustomSelect from 'components/shared/CustomSelect';
import CustomTextField from 'components/shared/CustomTextField';
import { grey, green } from '@mui/material/colors';
import { EField } from 'models/enums';
import { FieldType, ScriptEnumType } from 'models/types';
import debounce from 'lodash.debounce';

type Props = {
	field: FieldType;
	handleOnChange: (value: string, field: FieldType) => void;
	storedValue?: string;
};

const MultiTypeField: FC<Props> = ({ field, handleOnChange, storedValue }) => {
	const { id, name, type, unit, defaultValue, nullable, typeObject } = field;
	const fieldValue = storedValue || (defaultValue as string) || '';
	const fontColor = storedValue ? green[50] : grey[700];
	const isRequired = !nullable;
	const [value, setValue] = useState(fieldValue);

	const handleTextfieldOnChange = (value: string, field: FieldType) => {
		setValue(value);
		handleDebounceTextfieldOnChange(value, field);
	};

	const handleDebounceTextfieldOnChange = debounce((value: string, field: FieldType) => {
		handleOnChange(value, field);
	}, 800);

	switch (type) {
		case EField.LIST:
			const { values } = typeObject as ScriptEnumType;
			return (
				<CustomSelect
					key={`select_${id}`}
					required={isRequired}
					label={name}
					typeObject={typeObject}
					value={value}
					values={values}
					onChange={(event) => handleOnChange(event.target.value as string, field)}
					sx={{ color: fontColor }}
				/>
			);
		case EField.TEXT:
		case EField.INT:
		case EField.FLOAT:
			return (
				<CustomTextField
					key={`texfield_${id}`}
					label={name}
					type={type}
					required={isRequired}
					unit={unit}
					value={value || ''}
					InputProps={{ inputProps: { style: { color: fontColor } } }}
					onChange={(event) => handleTextfieldOnChange(event.target.value, field)}
				/>
			);
		case EField.TOGGLE:
			const isChecked = fieldValue === 'true';
			return (
				<FormControlLabel
					key={`switch_${id}`}
					control={
						<Switch
							checked={isChecked}
							color="success"
							required={isRequired}
							onChange={(event) => handleOnChange(event.target.checked as unknown as string, field)}
							inputProps={{ 'aria-label': 'controlled' }}
						/>
					}
					label={name}
					sx={{ color: fontColor }}
				/>
			);
		default:
			return null;
	}
};

export default MultiTypeField;
