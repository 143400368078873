import { ChangeEvent, FC, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';
import { ValidationError } from 'yup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Typography } from '@mui/material';
import { createPlantDialogOpen } from 'store/atoms/plants/createPlantDialog';
import CustomTextField from 'components/shared/CustomTextField';
import { plantMutations } from 'net/mutations';
import MapBoxGL from 'components/Mapbox';
import { tempPlantAtom } from 'store/atoms/plants/tempPlant';
import { plantValidationSchema } from 'validation';

const PlantCreateDialog: FC = () => {
	const { user } = useAuth0();
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const dialogIsOpen = useRecoilValue(createPlantDialogOpen);
	const resetCreatePlantDialogOpen = useResetRecoilState(createPlantDialogOpen);
	const resetTempCreatedPlant = useResetRecoilState(tempPlantAtom);
	const [tempPlant, setTempPlant] = useRecoilState(tempPlantAtom);
	const { mutate: createPlant } = plantMutations.useCreatePlant();

	const closeDialog = () => {
		resetCreatePlantDialogOpen();
		resetTempCreatedPlant();
		if (errors.length) setErrors([]);
	};

	const handleValueOnChange = (event: ChangeEvent, key: string) => {
		if (!tempPlant) return;
		const { value } = event.target as HTMLInputElement;
		if (errors.length) setErrors([]);
		setTempPlant({
			...tempPlant,
			[key]: value,
		});
	};

	const onAddPlant = () => {
		const data = { ...tempPlant, createdBy: user?.name || '' };
		plantValidationSchema
			.validate(data, { abortEarly: false })
			.then(() => {
				createPlant(data);
				closeDialog();
			})
			.catch((validationErrors: ValidationError) => {
				setErrors(validationErrors.inner);
			});
	};

	const errorByKey = (key: string): ValidationError | null => {
		return errors.find((error) => error.path === key) || null;
	};

	return (
		<Dialog open={dialogIsOpen} onClose={closeDialog} keepMounted={false} fullWidth maxWidth="md">
			<DialogTitle>Add new Plant</DialogTitle>
			<DialogContent>
				<Grid container spacing={1} sx={{ marginBottom: 1 }}>
					<Grid item xs={6}>
						<CustomTextField
							required
							margin="dense"
							label="Plant name"
							fullWidth
							variant="outlined"
							value={tempPlant?.name || ''}
							error={!!errorByKey('name')}
							helperText={errorByKey('name')?.message}
							onChange={(event) => handleValueOnChange(event, 'name')}
						/>
					</Grid>
					<Grid item xs={6}>
						<CustomTextField
							required
							margin="dense"
							label="Owner name"
							fullWidth
							variant="outlined"
							value={tempPlant?.owner || ''}
							error={!!errorByKey('owner')}
							helperText={errorByKey('owner')?.message}
							onChange={(event) => handleValueOnChange(event, 'owner')}
						/>
					</Grid>
				</Grid>
				<MapBoxGL />
				<Typography color="error">{errorByKey('location')?.message}</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="success" onClick={onAddPlant}>
					Create
				</Button>
				<Button color="warning" onClick={closeDialog}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PlantCreateDialog;
