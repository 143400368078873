import { useHeaders } from 'hooks/useHeaders';
import { useMutation } from 'react-query';
import * as api from 'net/api';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { notificationAtom } from 'store/atoms/notification';
import { EResponseStatus } from 'models/enums';
import { GridColDef, useGridApiContext } from '@mui/x-data-grid';
import { BCSourceTypeAtom } from 'store/atoms/plants/BCSourceType';
import { sourceColumnsAtom } from 'store/atoms/boundConditions/sourceColumns';
import { generateBCColumn } from 'utils/helpers';
import { sourceRowsAtom } from 'store/atoms/boundConditions/sourceRows';
import { sourceHeaderToRenameAtom } from 'store/atoms/boundConditions/sourceHeaderToRename';
import { OnError } from './helpers';

export const useAddColumn = () => {
	const setRows = useSetRecoilState(sourceRowsAtom);
	const setColumns = useSetRecoilState(sourceColumnsAtom);
	const selectedBCSourceTypeId = useRecoilValue(BCSourceTypeAtom);
	const headers = useHeaders();
	const apiRef = useGridApiContext();
	const setNotification = useSetRecoilState(notificationAtom);

	return useMutation(
		(column: GridColDef) => api.boundariesConditions.addColumn(column, selectedBCSourceTypeId, apiRef, headers),
		{
			onSuccess: (data) => {
				setRows((currentRows) => {
					return currentRows.map((row) => {
						return {
							...row,
							[`${data.name}`]: 0,
						};
					});
				});

				setColumns((currentColumns) => {
					const newArray = [...currentColumns];

					const newColumn = generateBCColumn(data.name.toLocaleLowerCase(), data.name, 'number', true);
					newArray.splice(data.id, 0, newColumn);

					return newArray;
				});

				setNotification({
					message: `New Source ${data.name} has been created successfully!`,
					status: EResponseStatus.SUCCESS,
				});
			},
			onError: (error, name) => OnError(`ERROR: The source ${name} couldn't be created, ${error}`, setNotification),
		},
	);
};

export const useDeleteColumn = () => {
	const setRows = useSetRecoilState(sourceRowsAtom);
	const setColumns = useSetRecoilState(sourceColumnsAtom);
	const headers = useHeaders();
	const apiRef = useGridApiContext();
	const setNotification = useSetRecoilState(notificationAtom);

	return useMutation((column: GridColDef) => api.boundariesConditions.deleteColumn(column, apiRef, headers), {
		onSuccess: (data, variables) => {
			setRows((currentRows) => {
				return currentRows.map((row) => {
					const { [variables.field]: id, ...rest } = row;
					return rest;
				});
			});

			setColumns((currentColumns) => {
				const newArray = [...currentColumns].filter((column) => column.field !== variables.field);
				return newArray;
			});

			setNotification({
				message: `The Source ${variables.field} has been deleted successfully!`,
				status: EResponseStatus.SUCCESS,
			});
		},
		onError: (error, variables) =>
			OnError(`ERROR: The source ${variables.field} couldn't be deleted, ${error}`, setNotification),
	});
};

export const useRenameColumn = () => {
	const columnToRename = useRecoilValue(sourceHeaderToRenameAtom) || ({} as GridColDef);
	const setRows = useSetRecoilState(sourceRowsAtom);
	const setColumns = useSetRecoilState(sourceColumnsAtom);
	const setNotification = useSetRecoilState(notificationAtom);
	const headers = useHeaders();

	return useMutation((value: string) => api.boundariesConditions.renameColumn(columnToRename, headers), {
		onSuccess: (data, changedHeaderName) => {
			setRows((currentRows) => {
				const { headerName } = columnToRename;
				if (!headerName) return currentRows;
				return currentRows.map((row) => {
					const { [headerName]: name, ...rest } = row;
					return {
						...rest,
						[changedHeaderName]: row[headerName],
					};
				});
			});

			setColumns((currentColumns) => {
				const newArray = [...currentColumns].map((column) => {
					if (column.headerName === columnToRename.headerName) {
						return {
							...column,
							headerName: changedHeaderName,
						};
					}
					return column;
				});
				return newArray;
			});

			setNotification({
				message: `The source has been successfully renamed to ${changedHeaderName}!`,
				status: EResponseStatus.SUCCESS,
			});
		},
		onError: (error, name) => OnError(`ERROR: The source couldn't be renamed to ${name}, ${error}`, setNotification),
	});
};
