import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { UnitOperationInstanceType, FieldType, ScriptFunctionType } from 'models/types';
import { unitOperationQueries } from 'net/queries';
import { useNodes, Node } from 'react-flow-renderer';
import CustomSelect from 'components/shared/CustomSelect';
import MultiTypeField from './MultiTypeField';

const UnitOperationFields: FC = () => {
	const selectedNode = useNodes().find((node) => node.selected) as Node<UnitOperationInstanceType>;
	const { data: unitOperation, refetch: refetchUnitOperation } = unitOperationQueries.useUnitOperationById(
		selectedNode?.data.unitOperationId,
	);
	const [selectedCalculationType, setSelectedCalculationType] = useState<string>();
	const [fields, setFields] = useState<FieldType[]>([]);

	useEffect(() => {
		if (selectedNode && !!unitOperation?.functions.length) {
			refetchUnitOperation();
			setSelectedCalculationType(unitOperation?.functions[0].classPath);
		}
	}, [refetchUnitOperation, selectedNode, unitOperation]);

	useEffect(() => {
		const selectedFunction: ScriptFunctionType =
			unitOperation?.functions.find((func) => func.classPath === selectedCalculationType) || ({} as ScriptFunctionType);
		if ('fields' in selectedFunction) {
			const concatsFields = [...selectedFunction.fields.concat(unitOperation?.fields as FieldType[])];
			setFields(concatsFields);
		}
	}, [selectedCalculationType, unitOperation]);

	if (!selectedNode || !unitOperation || !selectedCalculationType) return null;

	const handleCalculationTypeChange = (value: string) => {
		setSelectedCalculationType(value);
	};

	const handleOnChange = (value: string, field: FieldType) => {
		console.log(value, field);
		// const { inputValues } = (selectedUnitOperation as UnitOperationType) || [];
		// const foundInputValue = findInputValueById(+field.id, inputValues);
		// if (!foundInputValue) return;
		// const newFieldValue: UnitOperationInstanceValueWithFieldDataType = {
		// 	id: foundInputValue.id,
		// 	unitOperationInstanceId: selectedUnitOperation.id,
		// 	scriptObjectInstanceId: foundInputValue.scriptObjectInstanceId,
		// 	fieldId: foundInputValue.fieldId,
		// 	fieldName: field.name,
		// 	value,
		// };
		// updateInputValue(newFieldValue);
	};

	const calucaltionTypeIsInList = (type: string) => {
		return unitOperation.functions.some((func) => func.classPath === type);
	};

	return (
		<Box>
			{!!unitOperation.functions.length && (
				<CustomSelect
					label="Calculation Types"
					value={calucaltionTypeIsInList(selectedCalculationType) ? selectedCalculationType : ''}
					values={unitOperation.functions.map((func) => func.classPath)}
					nullable={false}
					onChange={(event) => handleCalculationTypeChange(event.target.value as unknown as string)}
				/>
			)}
			{!!fields.length ? (
				fields?.map((field) => {
					return (
						<MultiTypeField
							key={`multifield_${field.id}`}
							field={field}
							handleOnChange={handleOnChange}
							storedValue={undefined}
						/>
					);
				})
			) : (
				<Typography>Nothing to show!</Typography>
			)}
		</Box>
	);
};

export default UnitOperationFields;
