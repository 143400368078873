import { FC, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Collapse, List } from '@mui/material';
import { unitOperationLibraryAtom } from 'store/atoms/unitOperations/unitOperationLibrary';
import { SimpleUnitOperationType } from 'models/types';
import { leftDrawerOpenAtom } from 'store/atoms/drawer/leftDrawerOpen';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { MenuObjectType } from '../MenuTypes';
import MenuCollapsibleItem from '../MenuItems/MenuCollapsibleItem';
import MenuItem from '../MenuItems/MenuItem';

const MENU_DATA: MenuObjectType[] = [
	{
		name: 'Unit Operations',
		description: '',
		visible: true,
		disabled: false,
		isHeader: false,
		iconStart: AccountTreeIcon,
		collapsible: true,
	},
];

const LibraryMenu: FC = () => {
	const unitOperationLibrary = useRecoilValue<SimpleUnitOperationType[]>(unitOperationLibraryAtom);
	const [leftDrawerOpen, setLeftDrawerOpen] = useRecoilState(leftDrawerOpenAtom);
	const [itemOpenIndex, setItemOpenIndex] = useState<number>(-1);

	useEffect(() => {
		if (!leftDrawerOpen) setItemOpenIndex(-1);
	}, [leftDrawerOpen]);

	const handleOnCLick = (index: number) => {
		setItemOpenIndex((current) => (current === index ? -1 : index));
		!leftDrawerOpen && setLeftDrawerOpen(true);
	};

	const getUnitOperationsLibrary = () => {
		return unitOperationLibrary.map((item) => {
			return <MenuItem key={`item_${item.name}`} selected={false} data={item} sx={{ pl: 2 }} />;
		});
	};

	return (
		<List component="nav" aria-labelledby="nested-list">
			{MENU_DATA.map((item: MenuObjectType, index: number) => {
				const { collapsible, visible, name } = item;
				if (!visible) return null;
				const isOpen = index === itemOpenIndex;
				return collapsible ? (
					<MenuCollapsibleItem
						key={`collapsible_item_${item.name}`}
						selected={false}
						callBack={() => handleOnCLick(index)}
						isOpen={isOpen}
						data={item}
					>
						<Collapse in={isOpen} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{unitOperationLibrary?.length ? getUnitOperationsLibrary() : null}
							</List>
						</Collapse>
					</MenuCollapsibleItem>
				) : (
					<MenuItem selected={false} key={`item_${name}`} data={item} />
				);
			})}
		</List>
	);
};

export default LibraryMenu;
