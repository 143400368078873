import { EResponseStatus } from 'models/enums';
import { UnitOperationInstanceCreateParamsType, NotificationType, UnitOperationInstanceType } from 'models/types';
import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { notificationAtom } from 'store/atoms/notification';
import * as api from 'net/api';
import { useHeaders } from 'hooks/useHeaders';
import { addUnitOperationInstanceDataToNode } from 'utils/helpers';
import { useReactFlow } from 'react-flow-renderer';

export const useUpdatePos = () => {
	const setNotification = useSetRecoilState(notificationAtom);
	const headers = useHeaders();

	return useMutation((data: UnitOperationInstanceType) => api.unitOperationInstance.updatePosition(data, headers), {
		onSuccess: (data, variables) => {
			const payload: NotificationType = {
				message: `The position of the unit operation with id ${variables.id} has been updated!`,
				status: EResponseStatus.SUCCESS,
			};

			setNotification(payload);
		},
		onError: (error, variables) => {
			const payload: NotificationType = {
				message: `ERROR: The position of the unit operation with id: ${variables.id} couldn't be updated, ${error}`,
				status: EResponseStatus.ERRORED,
			};

			setNotification(payload);
		},
	});
};

export const useCreate = () => {
	const reactFlowInstance = useReactFlow();
	const headers = useHeaders();

	return useMutation((data: UnitOperationInstanceCreateParamsType) => api.unitOperationInstance.create(data, headers), {
		onSuccess: (data) => {
			reactFlowInstance.setNodes((nds) => {
				return addUnitOperationInstanceDataToNode(nds, data);
			});
		},
	});
};
