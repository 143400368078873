import { FC } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { FieldType } from 'models/types';

type Props = TextFieldProps & Partial<FieldType>;

const StyledTextField = styled(TextField)(({ theme }) => ({
	'& .MuiTextField-root': {
		marginTop: 30,
		marginBottom: 30,
	},
	'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
		display: 'none',
	},
	'& input[type=number]': {
		MozAppearance: 'textfield',
	},
	'& .MuiOutlinedInput-root': {
		paddingRight: 0,
	},
	'& .MuiInputAdornment-root': {
		backgroundColor: theme.palette.divider,
		padding: '20px 8px',
		borderTopRightRadius: theme.shape.borderRadius + 'px',
		borderBottomRightRadius: theme.shape.borderRadius + 'px',
	},
	'& .MuiFormLabel-root': {
		fontSize: '0.8rem',
	},
	'& .MuiInputLabel-shrink': {
		fontSize: '1rem',
	},
}));

const CustomTextField: FC<Props> = ({ label, type, unit, ...props }) => {
	return (
		<StyledTextField
			label={label}
			size="small"
			type={type?.toLocaleLowerCase()}
			InputProps={{
				endAdornment: unit && <InputAdornment position="end">{unit}</InputAdornment>,
			}}
			InputLabelProps={{ style: { left: 'unset', top: 'unset' } }}
			{...props}
		/>
	);
};

export default CustomTextField;
